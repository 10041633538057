import React from 'react';
import PropTypes from 'prop-types';

import { Images } from '../../../Themes';
import { cloudinaryImageUrl } from '../../../Services/Cloudinary';

interface CloudinaryImageProps extends React.HTMLProps<HTMLImageElement> {
  publicId: string;
  alt: string;
  transformation?: string;
  defaultImage?: string;
}

function CloudinaryImage({
  publicId,
  alt,
  defaultImage = Images.logo.engage.black,
  transformation,
  ...props
}: CloudinaryImageProps) {
  const src = cloudinaryImageUrl(publicId, transformation) || defaultImage;

  return <img src={src} alt={alt} {...props} />;
}

CloudinaryImage.propTypes = {
  publicId: PropTypes.string,
  alt: PropTypes.string.isRequired,
  // Cloudinary URL API where the transformation params are being listed
  // https://cloudinary.com/documentation/transformation_reference
  transformation: PropTypes.string,
  defaultImage: PropTypes.string,
};

export default CloudinaryImage;
