import { takeLatest, takeLeading, all } from 'redux-saga/effects';

/* ------------- API ------------- */
import api from '../Services/Api';

/* ------------- Types ------------- */
import { StartupTypes } from '../Redux/StartupRedux';
import { AuthTypes } from '../Redux/AuthRedux';
import { PostStatusTypes } from '../Redux/PostStatusRedux';
import { ProfileTypes } from '../Redux/ProfileRedux';
import { TranslationTypes } from '../Redux/TranslationRedux';
/* ------------- Sagas ------------- */

import startup from './StartupSagas';
import { getProfile, logout } from './AuthenticationSagas';

import { updateProfile, getMyEntities, updatePassword, createPassword } from './ProfileSagas';

// POSTS

import { translatePost, translateComment } from './TranslationSagas';

import getPostStatus from './PostStatusSagas';

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup),

    takeLeading(AuthTypes.GET_PROFILE_REQUEST, getProfile, api),
    takeLatest(AuthTypes.UPDATE_PROFILE_REQUEST, updateProfile, api),

    takeLatest(AuthTypes.LOGOUT_REQUEST, logout, api),

    takeLatest(PostStatusTypes.GET_POST_STATUS_REQUEST, getPostStatus, api),

    takeLeading(ProfileTypes.GET_MY_ENTITIES_REQUEST, getMyEntities, api),

    takeLeading(ProfileTypes.UPDATE_PASSWORD_REQUEST, updatePassword, api),
    takeLeading(ProfileTypes.CREATE_PASSWORD_REQUEST, createPassword, api),

    takeLatest(TranslationTypes.TRANSLATE_POST_REQUEST, translatePost, api),
    takeLatest(TranslationTypes.TRANSLATE_COMMENT_REQUEST, translateComment, api),
  ]);
}
