import React, { useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

const defaultCookieSettings = {
  facebook: false,
  google: false,
  youtube: false,
  vimeo: false,
};

const defaultContextValue = {
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleModal: (data: any) => {},
  settings: defaultCookieSettings,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update: (data: any) => {},
};

export const CookieSettingsContext = React.createContext(defaultContextValue);

export function CookieSettingsProvider({ children }: any) {
  const [isOpen, toggleModal] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [settings, setSettings] = useState({
    ...defaultCookieSettings,
    ...(cookies['cookie-settings'] || {}),
  });

  const update = (data: any) => {
    setCookie('cookie-settings', JSON.stringify(data), { path: '/' });
    setSettings(data);
  };

  const value = useMemo(() => {
    return {
      isOpen,
      toggleModal,
      settings,
      update,
    };
  }, [isOpen, settings]);

  return <CookieSettingsContext.Provider value={value}>{children}</CookieSettingsContext.Provider>;
}

export default CookieSettingsContext;
