import { call, put } from 'redux-saga/effects';

import AuthActions from '../Redux/AuthRedux';
import { currentLocale, setCurrentLocale } from '../I18n';

export default function* startup() {
  yield call(setCurrentLocale, currentLocale);

  if (!navigator.userAgent.includes('prerender')) {
    yield put(AuthActions.getProfileRequest());
  }
}
