import React, { useState, useEffect } from 'react';

import FullScreenLoader from '../FullScreenLoader';

export default function DelayedLoader() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!show) {
    return null;
  }

  return <FullScreenLoader />;
}
