import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';

import useWidth from '../../Hooks/useWidth';
import useCookieSettings from '../../Hooks/useCookieSettings';
import Dialog from '../DesignSystem/Dialog';

function CookieSettingsModal() {
  const intl = useIntl();
  const fullScreen = useWidth('down', 'sm');
  const { toggleModal, isOpen, update, settings } = useCookieSettings();

  const { control, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      ...settings,
    },
  });

  const onSubmit = (data: any) => {
    update(data);
    reset(data);
    toggleModal(false);
  };

  const onClose = () => {
    reset();
    toggleModal(false);
  };

  return (
    <Dialog
      id="cookie-settings-modal"
      label="cookie-settings-modal"
      fullScreen={fullScreen}
      open={isOpen}
      dismissible
      onClose={onClose}
      title={<FormattedMessage id="SETTINGS.COOKIES.TITLE" />}
    >
      <Box m={2}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Typography variant="body1" gutterBottom>
            <FormattedMessage id="SETTINGS.COOKIES.DESCRIPTION" />
          </Typography>

          <List>
            {Object.keys(settings).map((key, idx, list) => (
              <Fragment key={key}>
                <ListItem sx={{ paddingLeft: 0, paddingTop: 2 }}>
                  <ListItemText
                    primary={
                      <Typography variant="h6" component="h2" gutterBottom>
                        <FormattedMessage id={`SETTINGS.COOKIES.${key.toUpperCase()}.TITLE`} />
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body1">
                        <FormattedMessage
                          id={`SETTINGS.COOKIES.${key.toUpperCase()}.DESCRIPTION`}
                        />
                      </Typography>
                    }
                  />

                  <ListItemSecondaryAction>
                    <Controller
                      name={key}
                      control={control}
                      render={({ field }: any) => (
                        <Checkbox
                          {...field}
                          edge="end"
                          checked={field.value}
                          onChange={(e: any) => field.onChange(e.target.checked)}
                          inputProps={{
                            'aria-label': intl.formatMessage({
                              id: `SETTINGS.COOKIES.${key.toUpperCase()}.TITLE`,
                            }),
                          }}
                        />
                      )}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                {idx !== list.length - 1 && <Divider component="li" />}
              </Fragment>
            ))}
          </List>

          <Box my={2}>
            <Button type="submit" variant="contained">
              <FormattedMessage id="BUTTONS.UPDATE" />
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default CookieSettingsModal;
