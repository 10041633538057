import { persistCombineReducers } from 'redux-persist';

import ReduxPersist from './ReduxPersist';
import configureStore from './CreateStore';
import rootSaga from '../Sagas';
import { reducer as auth } from './AuthRedux';
import { reducer as entities } from './EntitiesRedux';
import { reducer as errorPages } from './ErrorPagesRedux';
import { reducer as modals } from './ModalsRedux';
import { reducer as postStatus } from './PostStatusRedux';
import { reducer as profile } from './ProfileRedux';
import { reducer as router } from './RouterRedux';
import { reducer as translation } from './TranslationRedux';

const storeConfiguration = () => {
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = persistCombineReducers(ReduxPersist.storeConfig, {
    auth,
    entities,
    errorPages,
    modals,
    postStatus,
    profile,
    router,
    translation,
  });

  return configureStore(rootReducer, rootSaga);
};

// create our store and persistor
export const { persistor, store } = storeConfiguration();
