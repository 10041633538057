import engageBlack from '../Assets/Images/Logos/efalia_engage_black.svg';
import defaultBanner from '../Assets/Images/Entities/default-banner_picture.png';
import defaultLogo from '../Assets/Images/Entities/default-logo_picture.png';
import anomaly from '../Assets/Images/Posts/Backgrounds/cover_anomaly.png';
import article from '../Assets/Images/Posts/Backgrounds/cover_article.png';
import event from '../Assets/Images/Posts/Backgrounds/cover_event.png';
import idea from '../Assets/Images/Posts/Backgrounds/cover_idea.png';
import ideasRequest from '../Assets/Images/Posts/Backgrounds/cover_ideas_request.png';
import participatoryBudget from '../Assets/Images/Posts/Backgrounds/cover_participatory_budget.png';
import collaborativeDecision from '../Assets/Images/Posts/Backgrounds/cover_collaborative_decision.png';
import folder from '../Assets/Images/Posts/Backgrounds/cover_folder.png';
import study from '../Assets/Images/Posts/Backgrounds/cover_study.png';
import userDefaultPicture from '../Assets/Images/Users/placeholder_citizen.png';
import userIdentityCardRecto from '../Assets/Images/Users/Identity/FR-new-id-front.png';
import userIdentityCardVerso from '../Assets/Images/Users/Identity/FR-new-id-back.png';
import serverError from '../Assets/Images/pages/server_error.png';
import entitySubscription from '../Assets/Images/Modals/new_entity_subscription.png';

const images = {
  logo: {
    engage: {
      black: engageBlack,
    },
  },
  entities: {
    defaultBanner,
    defaultLogo,
  },
  posts: {
    banner: {
      anomaly,
      article,
      demand: idea,
      default: idea,
      event,
      idea,
      ideas_request: ideasRequest,
      participatory_budget: participatoryBudget,
      collaborative_decision: collaborativeDecision,
      folder,
      study,
    },
  },
  user: {
    userDefaultPicture,
    identity: {
      recto: userIdentityCardRecto,
      verso: userIdentityCardVerso,
    },
  },
  pages: {
    serverError,
  },
  modals: {
    entity_subscription: entitySubscription,
  },
};

export default images;
