import React from 'react';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { stripHtml } from '../../Utils';
import defaultShareImage from '../../Assets/Images/SocialMedia/engage-meta-fr.jpg';
import OpenGraph from './OpenGraph';
import Twitter from './Twitter';
import PublishingInfo from './PublishingInfo';
import Locales from '../../Utils/Types/Locales';
import { getFacebookAppId, getGoogleClientId } from '../../Utils/OAuth';

const RECOMMENDED_DESCRIPTION_LENGTH = 170;

function MetaTags({
  title,
  description,
  pictureUrl,
  ogTypeCurrentContextValue,
  publishedAtIso8601,
  updatedAtIso8601,
  fixedImageSize,
  noindex,
}: any) {
  const space = useSelector((state: any) => state.entities.entity);
  const sanitizedDescription = stripHtml(description).slice(0, RECOMMENDED_DESCRIPTION_LENGTH);
  const { origin, pathname, search } = window.location;
  const cleanupUrl = origin + pathname;
  const askedLocale = qs.parse(search)?.lang;
  const cleanupUrlWithLocale = askedLocale ? `${cleanupUrl}?lang=${askedLocale}` : cleanupUrl;

  if (noindex) {
    return (
      <Helmet defaultTitle={space.name}>
        <meta name="robots" content="noindex" />
        <meta name="prerender-status-code" content="404" />
        {title && <title>{title}</title>}
      </Helmet>
    );
  }

  return (
    <>
      <Helmet defaultTitle={space.name}>
        {title && <title>{title}</title>}
        <meta property="fb:app_id" content={getFacebookAppId(space)} />
        <meta name="google-signin-client_id" content={getGoogleClientId(space)} />
        {description && <meta name="description" content={sanitizedDescription} />}
        <link rel="canonical" href={cleanupUrlWithLocale} />
        <link rel="alternate" hrefLang="x-default" href={cleanupUrl} />
        {Locales.map((locale) => (
          <link
            key={`metalocale+${locale}`}
            rel="alternate"
            hrefLang={locale.value}
            href={`${cleanupUrl}?lang=${locale.value}`}
          />
        ))}
        <meta name="theme-color" content={space.custom_colors?.primary_color} />
      </Helmet>

      {fixedImageSize && (
        <Helmet>
          <meta property="og:image:height" content="628" />
          <meta property="og:image:width" content="1200" />
        </Helmet>
      )}

      <Twitter />
      <OpenGraph
        title={title}
        space={space}
        description={sanitizedDescription}
        pictureUrl={pictureUrl || defaultShareImage}
        ogTypeCurrentContextValue={ogTypeCurrentContextValue}
      />
      <PublishingInfo publishedAtIso8601={publishedAtIso8601} updatedAtIso8601={updatedAtIso8601} />
    </>
  );
}

export default MetaTags;
