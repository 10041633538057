import { createTheme } from '@mui/material/styles';

const createCustomMuiTheme = (theme: any, primaryColor: any, secondaryColor: any) => {
  return createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: primaryColor || theme.palette.primary.main,
      },
      secondary: {
        main: secondaryColor || theme.palette.secondary.main,
      },
    },
  });
};

export default createCustomMuiTheme;
