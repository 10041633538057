import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  translatePostRequest: ['id', 'targetLang', 'sourceLang'],
  translateCommentRequest: ['id', 'postId', 'targetLang', 'sourceLang'],
  translateRecordSuccess: ['recordType', 'id', 'targetLang', 'result'],
  translateRecordFailure: ['recordType', 'id', 'targetLang'],
  setIsTranslationShown: ['id', 'isShown', 'recordType', 'targetLang'],
});

export const TranslationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  translations: {},
});

/* ------------- Reducers ------------- */
const translatePostRequest = (state: any, { id, targetLang }: any) => ({
  ...state,

  translations: {
    ...state.translations,
    [`post:${id}:${targetLang}`]: {
      ...state.translations[`post:${id}:${targetLang}`],
      isFetching: true,
      isSuccess: false,
      result: {},
    },
  },
});

const translateCommentRequest = (state: any, { id, targetLang }: any) => ({
  ...state,

  translations: {
    ...state.translations,
    [`comment:${id}:${targetLang}`]: {
      ...state.translations[`comment:${id}:${targetLang}`],
      isFetching: true,
      isSuccess: false,
      result: {},
    },
  },
});

const translateRecordSuccess = (state: any, { recordType, id, targetLang, result }: any) => ({
  ...state,

  translations: {
    ...state.translations,
    [`${recordType}:${id}:${targetLang}`]: {
      ...state.translations[`${recordType}:${id}:${targetLang}`],
      isFetching: false,
      isSuccess: true,
      result,
    },
  },
});

const translateRecordFailure = (state: any, { id, recordType, targetLang }: any) => ({
  ...state,

  translations: {
    ...state.translations,
    [`${recordType}:${id}:${targetLang}`]: {
      isFetching: false,
    },
  },
});

const setIsTranslationShown = (state: any, { id, isShown, recordType, targetLang }: any) => ({
  ...state,

  translations: {
    ...state.translations,
    [`${recordType}:${id}:${targetLang}`]: {
      ...state.translations[`${recordType}:${id}:${targetLang}`],
      isShown,
    },
  },
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSLATE_POST_REQUEST]: translatePostRequest,
  [Types.TRANSLATE_COMMENT_REQUEST]: translateCommentRequest,
  [Types.TRANSLATE_RECORD_SUCCESS]: translateRecordSuccess,
  [Types.TRANSLATE_RECORD_FAILURE]: translateRecordFailure,
  [Types.SET_IS_TRANSLATION_SHOWN]: setIsTranslationShown,
});
