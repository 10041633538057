import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { Box } from '@mui/material';

const themeColor = (theme: any, variant: any) => {
  switch (variant) {
    case 'secondary':
      return theme.palette.secondary.main;
    case 'action':
      return theme.palette.action.active;
    case 'error':
      return theme.palette.error.main;
    case 'primary':
      return theme.palette.primary.main;
    case undefined:
      return 'inherit';
    default:
      return variant;
  }
};

function CustomIcon({ size = 24, src, color = 'action' }: any) {
  return (
    <Box
      sx={(theme: any) => ({
        height: size,

        '& svg': {
          width: size,
          height: size,
          fill: themeColor(theme, color),
        },

        '& path': {
          fill: themeColor(theme, color),
        },
      })}
    >
      <ReactSVG wrapper="span" src={src} />
    </Box>
  );
}

CustomIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  src: PropTypes.string.isRequired,
};

export default CustomIcon;
