import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ErrorPage from '../../Pages/ErrorPage';
import appsignal from '../../Services/ErrorReportingService';
import ErrorPagesActions from '../../Redux/ErrorPagesRedux';

type ErrorBoundaryProps = {
  children: any;
  status: any;
  showErrorPage: any;
  location: any;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    try {
      appsignal.sendError(error);
    } catch (e) {
      console.log(e);
    }

    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const { showErrorPage, location, status } = this.props;
    const { hasError } = this.state;

    if (prevProps.location !== location) {
      showErrorPage(null);
    } else if (hasError && status) {
      showErrorPage(500);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, status } = this.props;

    if (status === 404) {
      return <ErrorPage />;
    }

    if ((hasError || status === 500) && process.env.NODE_ENV !== 'development') {
      return <ErrorPage status={500} />;
    }

    return children;
  }
}

function ErrorBoundaryWithLocation(props: any) {
  const location = useLocation();

  return <ErrorBoundary {...props} location={location} />;
}

const mapStateToProps = (state: any) => ({
  status: state.errorPages.status,
});

const mapDispatchToProps = (dispatch: any) => ({
  showErrorPage: (status: any) => dispatch(ErrorPagesActions.showPage(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryWithLocation);
