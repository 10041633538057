import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function OpenGraph({
  title = null,
  description = null,
  pictureUrl = null,
  space,
  ogTypeCurrentContextValue = null,
}: any) {
  return (
    <Helmet>
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:type" content={ogTypeCurrentContextValue || 'website'} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content={space.name} />
      {pictureUrl && <meta property="og:image" content={pictureUrl} />}
      <meta property="og:image:alt" content={`${space.name} Logo`} />
    </Helmet>
  );
}

OpenGraph.propTypes = {
  description: PropTypes.string,
  ogTypeCurrentContextValue: PropTypes.string,
  pictureUrl: PropTypes.string,
  title: PropTypes.string,
};

export default OpenGraph;
