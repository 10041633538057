interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: any) => JSX.Element;
  children: any;
}

export default function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) {
  return condition ? wrapper(children) : children;
}
