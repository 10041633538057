import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RouterActions from '../Redux/RouterRedux';

// permits us to access history.push from outside of React router context
function ExternalHistoryRouter({ children }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const args = useSelector((state: any) => state.router.args);

  useEffect(() => {
    if (args !== null) {
      navigate(...args);

      dispatch(RouterActions.historyClear());
    }
  }, [args]);

  return children;
}

export default ExternalHistoryRouter;
