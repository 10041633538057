import React from 'react';
import { useIntl } from 'react-intl';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const LOADER_SIZE = 40;

const StyledContainer = styled(Box)(({ theme: { palette } }: any) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  backgroundColor: palette.grey[50],
}));

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  // To have the feeling it's verticaly aligned
  marginTop: -LOADER_SIZE * 2,
}));

function FullScreenLoader() {
  const intl = useIntl();

  return (
    <StyledContainer>
      <StyledCircularProgress
        size={LOADER_SIZE}
        aria-label={intl.formatMessage({ id: 'LOADING_MORE' })}
      />
    </StyledContainer>
  );
}

export default FullScreenLoader;
