import React from 'react';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Avatar as MuiAvatar, Badge, Theme, Tooltip } from '@mui/material';

import { Images } from '../../../Themes';
import ConditionalWrapper from '../../ConditionalWrapper';
import { getContrastYIQ, getColorForString } from '../../../Utils/Colors';

export const AVATAR_SIZES = [30, 40, 48, 66, 96];

const StyledBadge = styled(Badge)(({ theme }: any) => ({
  '& .MuiBadge-badge svg': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

function Avatar({
  hasBorder = false,
  alt = '',
  badge = null,
  fullName = '',
  initials = '',
  placeholderType = 'user',
  size = 66,
  src = null,
  tooltip = false,
}: any) {
  const theme = useTheme() as Theme;
  const backgroundColor = getColorForString(fullName, theme.palette.primary.main);
  const textColor = getContrastYIQ(backgroundColor);
  const srcToUse = !src && placeholderType === 'entity' ? Images.entities.defaultLogo : src;

  return (
    <ConditionalWrapper
      condition={tooltip}
      wrapper={(children: any) => (
        <Tooltip arrow title={fullName} placement="top">
          {children}
        </Tooltip>
      )}
    >
      <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={badge}
      >
        <MuiAvatar
          alt={alt}
          src={srcToUse}
          sx={{
            height: size,
            width: size,
            fontSize: size < 50 ? size * (9 / 20) : size / 3,
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: '50%',
            border: hasBorder ? `${theme.spacing(0.5)} solid white` : 'none',
            display: 'flex',
            justifyContent: 'center',
            textTransform: 'uppercase',
            alignItems: 'center',
            backgroundColor: !src ? backgroundColor : 'transparent',
            color: textColor,
          }}
        >
          {initials}
        </MuiAvatar>
      </StyledBadge>
    </ConditionalWrapper>
  );
}

Avatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  initials: PropTypes.string,
  hasBorder: PropTypes.bool,
  size: PropTypes.oneOf(AVATAR_SIZES),
  badge: PropTypes.node,
  placeholderType: PropTypes.oneOf(['user', 'entity']),
  fullName: (props: any, propName: any) => {
    if (
      props.placeholderType === 'user' &&
      props[propName] === undefined &&
      typeof props[propName] !== 'string'
    ) {
      throw new Error('Please provider a fullName props when the placeholderType === user');
    }
  },
};

export default Avatar;
