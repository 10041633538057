import React, { useState } from 'react';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

import { MenuItemToMuiMenuItemProps } from '../../Utils/Space/navigation';

const StyledNavLink = React.forwardRef(({ className, ...props }: any, ref: any) => {
  const isValidLink = props.to && props.to !== '#';
  return (
    <NavLink
      ref={ref}
      className={({ isActive }: any) =>
        isActive && isValidLink ? `${className} Mui-selected` : className
      }
      {...props}
    />
  );
});

StyledNavLink.displayName = 'StyledNavLink';

interface DrawerMenuItemProps {
  item: MenuItemToMuiMenuItemProps;
  handleClose: () => void;
  child?: boolean;
}

export default function DrawerMenuItem({ item, handleClose, child }: DrawerMenuItemProps) {
  const { href, label, ariaLabel, menuItems, component, id } = item;
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton
        {...(child && { sx: { pl: 4 } })}
        key={id || label}
        {...(component ? { component, href } : { to: href })}
        component={component || StyledNavLink}
        onClick={menuItems?.length ? () => setOpen(!open) : handleClose}
        aria-label={ariaLabel}
        label={label}
      >
        <ListItemText primary={label} />
        {menuItems?.length && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItemButton>
      {menuItems?.length && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuItems.map((subItem: MenuItemToMuiMenuItemProps) => (
              <DrawerMenuItem key={subItem.id} item={subItem} handleClose={handleClose} child />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

DrawerMenuItem.displayName = 'DrawerMenuItem';
