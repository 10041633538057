import React from 'react';
import { Button, Menu } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

import useSpace from '../../../Hooks/useSpace';
import LanguageMenuItems from './LanguageMenuItems';
import { currentLocale } from '../../../I18n/index';
import { Locales } from '../../../Utils/Types';

function LanguageMenu() {
  const { space } = useSpace();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentLocaleObj = Locales.find((locale) => locale.value === currentLocale);

  if (!space) {
    return null;
  }

  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button size="large" color="inherit" onClick={openMenu} endIcon={<ArrowDropDownIcon />}>
        {currentLocaleObj?.value.toUpperCase()}
      </Button>

      <Menu
        id="language-menu-actions"
        role="list"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeMenu}
        MenuListProps={{ component: 'nav', disablePadding: true }}
      >
        <LanguageMenuItems onClick={closeMenu} />
      </Menu>
    </>
  );
}

export default LanguageMenu;
