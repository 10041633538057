import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

function ButtonWithLoader({ children, isFetching, ...props }: any) {
  return (
    <LoadingButton loading={isFetching} disabled={isFetching} {...props}>
      {children}
    </LoadingButton>
  );
}

export default ButtonWithLoader;
