import Appsignal from '@appsignal/javascript';
import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as pluginBreadcrumbsConsole } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';

const appsignal = new Appsignal({
  key: window.runtimeEnv.REACT_APP_APPSIGNAL_FRONTEND_API_KEY,
  revision: window.runtimeEnv.GIT_COMMIT_ID,
});

appsignal.use(pluginBreadcrumbsNetwork());
appsignal.use(pluginPathDecorator());

// Disable `pluginBreadcrumbsConsole` on dev since some console.log
// might not be stringified which would raise an error
if (process.env.NODE_ENV !== 'development') {
  appsignal.use(pluginBreadcrumbsConsole());
}

export default appsignal;
