import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import { Icons, Images } from '../../Themes';
import { spacePath } from '../../Utils/routes';

type ErrorPageType = 404 | 500 | 0;
type ErrorPageProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const StyledSvg = styled(ReactSVG)(({ theme: { palette } }: any) => ({
  '--primary-main': palette.primary.main,
  '--primary-dark': palette.primary.dark,
  '--primary-light': palette.primary.light,
  '& svg': {
    maxWidth: '100%',
    height: 'auto',
  },
}));

function ErrorPage({
  status = 404,
  name,
  slug,
}: {
  status?: ErrorPageType;
  name?: string;
  slug?: string;
}) {
  const space = useSelector((state: any) => state.entities.entity);

  const spaceName = name || space.name || 'Efalia Engage';
  const spaceSlug = slug || space.slug;

  let actionKey = 'ERROR_PAGES.RELOAD';
  let href = '#';

  if (status !== 0) {
    actionKey = spaceSlug ? 'ERROR_PAGES.BACK_TO_SPACE' : 'ERROR_PAGES.CTA_NO_SPACE';

    href = spaceSlug ? spacePath() : `https://${window.runtimeEnv?.REACT_APP_HOST_WWW}/home`;
  }

  const contents: Record<ErrorPageType, ErrorPageProps> = {
    404: {
      title: 'ERROR_PAGES.NOT_FOUND.TITLE',
      description: 'ERROR_PAGES.NOT_FOUND.DESCRIPTION',
      icon: <StyledSvg wrapper="span" src={Icons.misc.notFound} />,
    },
    500: {
      title: 'ERROR_PAGES.SERVER_ERROR.TITLE',
      description: 'ERROR_PAGES.SERVER_ERROR.DESCRIPTION',
      icon: <img src={Images.pages.serverError} width="100%" alt="Server error" />,
    },
    0: {
      title: 'ERROR_PAGES.OFFLINE.TITLE',
      description: 'ERROR_PAGES.OFFLINE.DESCRIPTION',
      icon: <StyledSvg wrapper="span" src={Icons.misc.offline} />,
    },
  };

  const handleClick = (event: any) => {
    if (status === 0) {
      window.location.reload();
      return event.preventDefault();
    }

    return true;
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{`${status} | ${spaceName}`}</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: { xs: 3, md: 12 } }}>
        <Grid container spacing={5} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Typography variant="h4" component="h1" color="textPrimary" align="left" sx={{ mb: 3 }}>
              <FormattedMessage id={contents[status].title} />
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              <FormattedMessage id={contents[status].description} />
            </Typography>
            <Button
              href={href}
              variant="contained"
              size="large"
              color="primary"
              rel="prefetch"
              onClick={handleClick}
              sx={{ alignSelf: 'flex-start' }}
            >
              <FormattedMessage id={actionKey} />
            </Button>
          </Grid>
          <Grid item xs={12} md={7}>
            {contents[status].icon}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ErrorPage;
