import React from 'react';
import { Box, Hidden, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { FormattedMessage, useIntl } from 'react-intl';

import { Images } from '../../Themes';
import {
  ACCESSIBILITY_LINKS,
  ETHICS_LINKS,
  FAQ_ADMIN_LINKS,
  FAQ_USER_LINKS,
  PRIVACY_LINKS,
  TERMS_LINKS,
} from '../../Utils/WebsiteUrls';
import { Groups } from '../../Utils/Types';
import { Role } from '../../Utils/Types/Roles';

export const FOOTER_HEIGHT = 65;
export const MOBILE_FOOTER_HEIGHT = 100;

const StyledContainer = styled(Box)(({ theme: { palette, spacing } }: any) => ({
  backgroundColor: palette.background.paper,
  padding: spacing(2, 3),
  zIndex: 20,
}));

const StyledEngageLogo = styled(ReactSVG)(() => ({
  width: '100px',
  '& svg': {
    verticalAlign: 'middle',
  },
}));

const StyledLink = styled(Link)(({ theme: { palette } }: any) => ({
  color: 'inherit',
  '&:hover': {
    opacity: 0.6,
    textDecoration: 'none',
  },
  '& g, & path': {
    fill: palette.text.primary,
  },
}));

function Footer({ role }: { role: Role }) {
  const { locale, formatMessage } = useIntl();
  const space = useSelector((state: any) => state.entities.entity);
  const isAdminOrFluimaster = Groups.ADMINS.includes(role as Role);

  if (!space) {
    return null;
  }

  const renderFooterLink = ({ textId, link }: any) => (
    <Box p={0.5}>
      <StyledLink
        variant="body1"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        title={formatMessage({ id: 'BUTTONS.OPEN_IN_NEW_TAB' })}
      >
        <FormattedMessage id={textId} />
      </StyledLink>
    </Box>
  );

  const renderDotSeparator = () => (
    <Box p={0.5}>
      <Typography variant="body2" color="inherit">
        &#8226;
      </Typography>
    </Box>
  );

  const renderLogo = () => (
    <Box px={0.5}>
      <StyledEngageLogo src={Images.logo.engage.black} aria-hidden="true" />
      <Box sx={{ display: 'none' }}>Efalia Engage logo to Efalia Engage website</Box>
    </Box>
  );

  const helpLink = isAdminOrFluimaster ? FAQ_ADMIN_LINKS[locale] : FAQ_USER_LINKS[locale];
  const termsLink = space.terms_of_use ? `/${space.slug}/pages/terms` : TERMS_LINKS[locale];
  const privacyLink = space.privacy_policy
    ? `/${space.slug}/pages/privacy-policy`
    : PRIVACY_LINKS[locale];

  return (
    <StyledContainer component="footer">
      <Hidden mdDown>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            display="flex"
            flexGrow={1}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {renderLogo()}
          </Box>

          <Box
            display="flex"
            flexGrow={1}
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {renderFooterLink({ textId: 'FOOTER.HELP', link: helpLink })}
            {renderDotSeparator()}
            {renderFooterLink({
              textId: 'FOOTER.ACCESSIBILITY',
              link: ACCESSIBILITY_LINKS[locale],
            })}
            {renderDotSeparator()}
            {renderFooterLink({ textId: 'FOOTER.ETHICAL_POLICY', link: ETHICS_LINKS[locale] })}
            {renderDotSeparator()}
            {renderFooterLink({ textId: 'FOOTER.TERMS_OF_USE', link: termsLink })}
            {renderDotSeparator()}
            {renderFooterLink({ textId: 'FOOTER.PRIVACY_POLICY', link: privacyLink })}
          </Box>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box display="flex" flexDirection="row" justifyContent="center">
          {renderLogo()}
        </Box>
        <Box display="flex" flexDirection="column">
          {renderFooterLink({ textId: 'FOOTER.HELP', link: helpLink })}
          {renderFooterLink({ textId: 'FOOTER.ACCESSIBILITY', link: ACCESSIBILITY_LINKS[locale] })}
          {renderFooterLink({ textId: 'FOOTER.ETHICAL_POLICY', link: ETHICS_LINKS[locale] })}
          {renderFooterLink({ textId: 'FOOTER.TERMS_OF_USE', link: termsLink })}
          {renderFooterLink({ textId: 'FOOTER.PRIVACY_POLICY', link: privacyLink })}
        </Box>
      </Hidden>
    </StyledContainer>
  );
}

export default Footer;
