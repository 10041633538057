import React, { ReactNode } from 'react';
import ConditionalWrapper from '../../ConditionalWrapper';
import { Badge } from '@mui/material';
import { Notifications } from '@mui/icons-material';

interface Props {
  verified?: boolean;
  children: ReactNode;
  noIcon?: boolean;
}

const VerifiedBadgeNotification: React.FC<Props> = ({
  verified = false,
  noIcon = false,
  children,
}) => {
  return (
    <ConditionalWrapper
      condition={!verified}
      wrapper={(children: ReactNode) => (
        <Badge
          color="error"
          badgeContent={noIcon ? '' : <Notifications sx={{ fontSize: 12 }} />}
          sx={{ padding: 0 }}
          variant={noIcon ? 'dot' : 'standard'}
        >
          {children}
        </Badge>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

export default VerifiedBadgeNotification;
