import { ProfileTypes } from './ProfileRedux';
import { loginPath } from '../Utils/routes';

// The lists of actions that require the user to be logged in
const privateActions = [ProfileTypes.JOIN_ENTITY_REQUEST];

const AuthenticationMiddleware =
  ({ getState }: any) =>
  (next: any) =>
  (action: any) => {
    const state = getState();

    if (privateActions.includes(action.type) && !state.auth.isLoggedIn) {
      state.router.args = loginPath();
    }

    return next(action);
  };

export default AuthenticationMiddleware;
