import React from 'react';
import { Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Mail as MailIcon, AccountCircle as AccountIcon, ExitToApp } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import VerifiedBadgeNotification from '../Auth/VerifiedBadgeNotification';
import CustomIcon from '../DesignSystem/CustomIcon';
import { Icons } from '../../Themes';
import AnalyticsService from '../../Services/AnalyticsService';
import AuthActions from '../../Redux/AuthRedux';
import useSpace from '../../Hooks/useSpace';

const StyledNavLink = React.forwardRef(({ className, ...props }: any, ref: any) => (
  <NavLink
    ref={ref}
    className={({ isActive }: any) => (isActive ? `${className} Mui-selected` : className)}
    {...props}
  />
));

StyledNavLink.displayName = 'StyledNavLink';

function ProfileMenuItems({ onClick, nested = false, verified }: any) {
  const { space } = useSpace();
  const dispatch = useDispatch();

  // Sign out from Google Auth SDK
  // just in case user signed in via Google
  const handleGoogleSignOut = () => {
    if (window.gapi && window.gapi.auth2) {
      window.gapi.auth2.getAuthInstance().signOut();
    }
  };

  const handleSignOut = () => {
    AnalyticsService.track('Authentication', 'Logout');

    handleGoogleSignOut();
    dispatch(AuthActions.logoutRequest());
    onClick();
  };

  return (
    <>
      <ListItemButton
        onClick={onClick}
        role="list"
        to={`/${space.slug}/account/settings`}
        component={StyledNavLink}
        sx={{ ...(nested && { pl: 4 }) }}
      >
        <ListItemIcon>
          <VerifiedBadgeNotification verified={verified} noIcon={true}>
            <AccountIcon />
          </VerifiedBadgeNotification>
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="NAVBAR.MY_ACCOUNT" />
        </ListItemText>
      </ListItemButton>

      <ListItemButton
        onClick={onClick}
        role="list"
        to={`/${space.slug}/contributions/participation`}
        component={StyledNavLink}
        sx={{ ...(nested && { pl: 4 }) }}
      >
        <ListItemIcon>
          <CustomIcon src={Icons.misc.bulb} />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="SPACE.CONTRIBUTIONS.PROPOSALS.TITLE" />
        </ListItemText>
      </ListItemButton>

      <ListItemButton
        onClick={onClick}
        role="list"
        to={`/${space.slug}/contributions/messaging`}
        component={StyledNavLink}
        sx={{ ...(nested && { pl: 4 }) }}
      >
        <ListItemIcon>
          <MailIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="SPACE.CONTRIBUTIONS.MESSAGING.TITLE" />
        </ListItemText>
      </ListItemButton>
      <Divider />
      <ListItemButton onClick={handleSignOut} role="list" sx={{ ...(nested && { pl: 4 }) }}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="SIGN_OUT" />
        </ListItemText>
      </ListItemButton>
    </>
  );
}

export default ProfileMenuItems;
