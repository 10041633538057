import { hsv2Hex, hex2Hsv } from 'colorsys';
import { darken, getContrastRatio } from '@mui/material/styles';

/**
 * Calculates the color for a given string, based on the Space primary color
 *
 * @param  String  string
 * @param  String  primary color to base the hue on
 * @return String  color based on string
 */
export const getColorForString = (name: string, primaryColor: any, saturation = 70, value = 50) => {
  if (!name || !name.trim()) return '#BBBBBB';

  const uuid = Array.from(name).reduce((acc: number, char: string) => acc * char.charCodeAt(0), 1);
  let hueValueBase = 0;

  if (primaryColor) {
    hueValueBase = hex2Hsv(primaryColor).h - 72;
  }

  const possibleHueValues = Array.from(Array(144).keys()).map((v) => v + hueValueBase);

  return hsv2Hex(possibleHueValues[uuid % 144], saturation, value);
};

/**
 * Calculates the color contrast, helps determine whether the color should be
 * dark or light based on the backgroundColor
 *
 * @param  String  hexColor the backgroundColor
 * @return String  either back or white, based on the hexColor
 */
export const getContrastYIQ = (hexColor: any) => {
  const color = hexColor.replace('#', '');

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? 'black' : 'white';
};

export const rgbStringToHex = (rgbString: any) => {
  if (!rgbString.includes('rgb')) {
    return rgbString;
  }

  const numbers = rgbString.replaceAll(/\s/g, '').split('(')[1].split(')')[0].split(',');

  const hexValues = numbers.map((x: any) => {
    // For each array element
    const base16 = parseInt(x, 10).toString(16); // Convert to a base16 string
    return base16.length === 1 ? `0${x}` : base16; // Add zero if we get only one character
  });
  return `#${hexValues.join('')}`;
};

const CONTRAST_RATIO = 4.5;
export const getContrastedForeground = (
  foreground: any,
  background: any,
  contrastRatio = CONTRAST_RATIO,
) => {
  let newForeground = foreground;

  while (getContrastRatio(newForeground, background) < contrastRatio) {
    newForeground = darken(newForeground, 0.01);
  }

  return rgbStringToHex(newForeground);
};
