import Roles, { Role } from './Roles';

type Group = 'all' | 'admins' | 'creators' | 'users' | 'contributors';
type GroupUpperCase = Uppercase<Group>;

const Groups: Record<GroupUpperCase, Role[]> = {
  ALL: Object.values(Roles),
  ADMINS: [Roles.ADMINISTRATOR, Roles.FLUIMASTER],
  CREATORS: [Roles.CONTRIBUTOR, Roles.ADMINISTRATOR, Roles.FLUIMASTER],
  USERS: [Roles.CITIZEN, Roles.CONTRIBUTOR],
  CONTRIBUTORS: [Roles.CONTRIBUTOR],
};

export default Groups;
