import React from 'react';
import { createRoot } from 'react-dom/client';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';

import './Components/ErrorPage';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
