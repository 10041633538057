import React from 'react';
import {
  AssignmentInd as AssignmentIndIcon,
  Folder as FolderIcon,
  InsertChart as InsertChartIcon,
  Mail as MailIcon,
  ContactMail as ContactMailIcon,
  Settings as SettingsIcon,
  SupervisorAccount as SupervisorAccountIcon,
  HistoryEdu as HistoryEduIcon,
  Campaign as CampaignIcon,
} from '@mui/icons-material';

import { Groups } from '../Types';
import { Category } from '../Types/Posts';

export interface MenuItemProps {
  key: string;
  icon?: React.FC;
  path?: string;
  authorizedRoles?: Array<string>;
  subItems?: Array<MenuItemProps>;
}

export const dashboardPaths: Record<Category | string, string> = {
  STATS: 'stats',
  FOLDER: 'folders',
  CONSULTATION: 'consultations',
  PARTICIPATION: 'participations',
  COMMUNICATION: 'news',
  MESSAGING: 'messaging',
  ASSIGNMENT: 'assignments',
  NEWSLETTER: 'newsletters',
  USER: 'users',
  SETTINGS: 'settings',
  SETTINGS_APPEARANCE: 'settings/appearance',
  SETTINGS_PAGES: 'settings/pages',
  SETTINGS_ACCESS: 'settings/access',
  SETTINGS_LEGAL: 'settings/legal',
  SETTINGS_COMMUNICATION: 'settings/communication',
};

export const menuItems: MenuItemProps[] = [
  {
    key: 'STATS',
    icon: InsertChartIcon,
    path: dashboardPaths.STATS,
    authorizedRoles: Groups.ADMINS,
  },
  {
    key: 'FOLDER',
    icon: FolderIcon,
    path: dashboardPaths.FOLDER,
    authorizedRoles: Groups.CREATORS,
  },
  {
    key: 'CONSULTATION',
    icon: CampaignIcon,
    path: dashboardPaths.CONSULTATION,
    authorizedRoles: Groups.CREATORS,
  },
  {
    key: 'COMMUNICATION',
    icon: HistoryEduIcon,
    path: dashboardPaths.COMMUNICATION,
    authorizedRoles: Groups.CREATORS,
  },
  {
    key: 'MESSAGING',
    icon: MailIcon,
    path: dashboardPaths.MESSAGING,
    authorizedRoles: Groups.ADMINS,
  },
  {
    key: 'ASSIGNMENT',
    icon: AssignmentIndIcon,
    path: dashboardPaths.ASSIGNMENT,
    authorizedRoles: Groups.CREATORS,
  },
  {
    key: 'NEWSLETTER',
    icon: ContactMailIcon,
    path: dashboardPaths.NEWSLETTER,
    authorizedRoles: Groups.CREATORS,
  },
  {
    key: 'USER',
    icon: SupervisorAccountIcon,
    path: dashboardPaths.USER,
    authorizedRoles: Groups.ADMINS,
  },
  {
    key: 'SETTINGS',
    icon: SettingsIcon,
    authorizedRoles: Groups.ADMINS,
    subItems: [
      {
        key: 'SETTINGS_APPEARANCE',
        path: dashboardPaths.SETTINGS_APPEARANCE,
      },
      {
        key: 'SETTINGS_PAGES',
        path: dashboardPaths.SETTINGS_PAGES,
      },
      {
        key: 'SETTINGS_ACCESS',
        path: dashboardPaths.SETTINGS_ACCESS,
      },
      {
        key: 'SETTINGS_LEGAL',
        path: dashboardPaths.SETTINGS_LEGAL,
      },
      {
        key: 'SETTINGS_COMMUNICATION',
        path: dashboardPaths.SETTINGS_COMMUNICATION,
      },
    ],
  },
];
