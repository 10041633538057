const Providers = {
  PROVIDER_NAME_EMAIL: 'email',
  PROVIDER_NAME_FACEBOOK: 'facebook',
  PROVIDER_NAME_GOOGLE: 'google_oauth2',
};

export const ProvidersForAnalytics = {
  [Providers.PROVIDER_NAME_EMAIL]: 'Efalia_Engage',
  [Providers.PROVIDER_NAME_FACEBOOK]: 'Facebook',
  [Providers.PROVIDER_NAME_GOOGLE]: 'Google',
};

export default Providers;
