import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import { engageMuiTheme, createCustomMuiTheme } from '../Themes';
import useSpace from '../Hooks/useSpace';
import { currentLocale } from '../I18n';

// Check if the user agent is Prerender. Speedy should be false if so.
const speedy = navigator.userAgent.toLowerCase().indexOf('prerender') === -1;

const cacheRtl = createCache({
  key: 'muirtl',
  speedy,
  stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({ key: 'muiltr', speedy });

function EngageThemeProvider({ children }: any) {
  const { space } = useSpace();
  const primaryColor = space?.custom_colors?.primary_color;
  const secondaryColor = space?.custom_colors?.secondary_color;

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={currentLocale === 'ar' ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={createCustomMuiTheme(engageMuiTheme, primaryColor, secondaryColor)}>
          <CssBaseline />

          {children}
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
}

export default EngageThemeProvider;
