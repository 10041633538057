import React from 'react';
import { Box, Menu, ListItemButton, ListItemText, Tab } from '@mui/material';
import { NavLink } from 'react-router-dom';

function NavbarLinkTab({ render, menuItems = [], label, hideLabel, ...props }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  if (render) {
    return render;
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Tab
        component={NavLink}
        iconPosition="start"
        label={hideLabel ? '' : label}
        {...props}
        sx={{
          color: (theme: any) => theme.palette.text.primary,
          height: 64,
          minHeight: 64,
          px: 3,
          '&:hover': {
            backgroundColor: (theme: any) => theme.palette.grey[100],
          },
        }}
        {...(menuItems.length > 0 && {
          'aria-controls': `navbar-menu-items-${props.value}`,
          'aria-haspopup': 'true',
          onClick: handleOpen,
        })}
      />

      <Menu
        id={`navbar-menu-items-${props.value}`}
        role="list"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transitionDuration={100}
        onClose={handleClose}
        MenuListProps={{ component: 'nav', disablePadding: true }}
      >
        {menuItems.map((item: any) => (
          <ListItemButton
            key={item.id}
            component={NavLink}
            to={item.href}
            target={item.target}
            sx={{ color: (theme: any) => theme.palette.text.primary }}
            onClick={handleClose}
          >
            <ListItemText primary={item.label} sx={{ fontWeight: 700 }} />
          </ListItemButton>
        ))}
      </Menu>
    </Box>
  );
}

export default NavbarLinkTab;
