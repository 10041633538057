import React, { useMemo } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import {
  ChevronRight as ChevronRightIcon,
  InsertChartOutlined,
  Language as LanguageIcon,
} from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { Groups, Locales } from '../../Utils/Types';
import Avatar from '../DesignSystem/Avatar';
import SignUpButton from './SignUpButton';
import ProfileMenuItems from './ProfileMenuItems';
import VerifiedBadgeNotification from '../Auth/VerifiedBadgeNotification';
import DrawerMenuItem from './DrawerMenuItem';
import DrawerSubMenu from './DrawerSubMenu';
import LanguageMenuItems from './LanguageMenu/LanguageMenuItems';
import { cloudinaryImageUrl, CloudinaryConfig } from '../../Services/Cloudinary';
import { MenuItemToMuiMenuItemProps, generateMuiMenuItems } from '../../Utils/Space/navigation';
import { currentLocale } from '../../I18n/index';

const StyledNavLink = React.forwardRef(({ className, ...props }: any, ref: any) => (
  <NavLink
    ref={ref}
    className={({ isActive }: any) => (isActive ? `${className} Mui-selected` : className)}
    {...props}
  />
));

StyledNavLink.displayName = 'StyledNavLink';

function DrawerMenu({
  space,
  role,
  userPictureS3Key,
  open,
  handleClose,
  isLoggedIn,
  initials,
  fullName,
  verified,
  variant = 'temporary',
}: any) {
  const intl = useIntl();
  const spaceMenuItems = useMemo(() => generateMuiMenuItems(space, intl), [space, intl]);
  const currentLocaleObj = Locales.find((locale) => locale.value === currentLocale);
  const navLinks = [];

  navLinks.push(...spaceMenuItems);

  if (!space?.slug) {
    return null;
  }

  const isMultilingual = space?.locales?.length > 1;

  return (
    <Drawer variant={variant} anchor="right" open={open} onClose={handleClose}>
      <Toolbar disableGutters>
        <IconButton
          aria-label="drawer menu"
          onClick={handleClose}
          size="large"
          sx={{ marginLeft: 1 }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Toolbar>

      <Divider />

      <List component="div" sx={{ minWidth: 250 }}>
        {navLinks.map((item: MenuItemToMuiMenuItemProps) => (
          <DrawerMenuItem handleClose={handleClose} item={item} key={item.label} />
        ))}

        {isLoggedIn && Groups.CREATORS.includes(role) && (
          <>
            <Divider />
            <ListItemButton component={StyledNavLink} to={`/${space.slug}/dashboard`}>
              <ListItemIcon>
                <InsertChartOutlined />
              </ListItemIcon>
              <ListItemText primary={<FormattedMessage id="NAVBAR.DASHBOARD" />} />
            </ListItemButton>
            <Divider />
          </>
        )}

        {isMultilingual && (
          <DrawerSubMenu label={currentLocaleObj?.label} icon={<LanguageIcon />}>
            <LanguageMenuItems onClick={handleClose} nested />
          </DrawerSubMenu>
        )}

        {isLoggedIn ? (
          <DrawerSubMenu
            label={<FormattedMessage id="NAVBAR.MY_PROFILE" />}
            icon={
              <VerifiedBadgeNotification verified={verified}>
                <Avatar
                  src={cloudinaryImageUrl(userPictureS3Key, CloudinaryConfig.AVATAR_40)}
                  fullName={fullName}
                  initials={initials}
                  size={30}
                  alt="user avatar"
                />
              </VerifiedBadgeNotification>
            }
          >
            <ProfileMenuItems onClick={handleClose} nested verified={verified} />
          </DrawerSubMenu>
        ) : (
          <ListItem onClick={handleClose}>
            <SignUpButton />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
}

export default DrawerMenu;
