import { useQuery } from 'react-query';

import { getUiSections } from '../Services/Api';
import { UiSection, Page } from '../Utils/Types/UiSection';

interface UseUiSectionsParams {
  enabled?: boolean;
  prefetch?: boolean;
}

function useUiSectionsQuery(
  page: Page,
  { enabled = false, prefetch = false }: UseUiSectionsParams = {},
) {
  const { data, isFetching, ...params } = useQuery(
    ['ui-setions', page],
    () => getUiSections(page),
    {
      enabled: prefetch ? enabled : !!window.spaceSlug,
      select: (response) => response.data,
      refetchOnWindowFocus: false,
    },
  );

  return {
    uiSections: (data?.data || []) as UiSection[],
    isFetching,
    ...params,
  };
}

export default useUiSectionsQuery;
