import { call, put } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';

import TranslationActions from '../Redux/TranslationRedux';
import Analytics from '../Services/AnalyticsService';

export function* translatePost(api: any, { id, targetLang, sourceLang }: any) {
  const response: ApiResponse<any> = yield call(api.getPostTranslation, id, targetLang);

  yield call(Analytics.track, 'Post - Translation', {
    'Post ID': id,
    fromLanguage: sourceLang,
    toLanguage: targetLang,
  });

  if (response.ok) {
    yield put(
      TranslationActions.translateRecordSuccess('post', id, targetLang, response.data.data),
    );
  } else {
    yield put(TranslationActions.translateRecordFailure('post', id, targetLang));
  }
}

export function* translateComment(api: any, { id, postId, targetLang, sourceLang }: any) {
  const response: ApiResponse<any> = yield call(api.getCommentTranslation, id, targetLang);

  yield call(Analytics.track, 'Comment - Translation', {
    'Post ID': postId,
    'Comment ID': id,
    fromLanguage: sourceLang,
    toLanguage: targetLang,
  });

  if (response.ok) {
    yield put(
      TranslationActions.translateRecordSuccess('comment', id, targetLang, response.data.data),
    );
  } else {
    yield put(TranslationActions.translateRecordFailure('comment', id, targetLang));
  }
}
