import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { AlertColor, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';

interface SnackbarContextType {
  showSnackbar: (message: string, severity?: AlertColor, duration?: number) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [search, setSearch] = useSearchParams();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  const [snackbarDuration, setSnackbarDuration] = useState<number>(5000);

  // Watch for changes in the search params
  useEffect(() => {
    const messageFromSearch = search.get('snackbar');
    const severityFromSearch = search.get('severity') as AlertColor;

    if (messageFromSearch) {
      setSnackbarMessage(messageFromSearch);
      setSnackbarSeverity(severityFromSearch || 'info');
      setSnackbarOpen(true);
    }
  }, [search]); // Re-run this effect when the search params change

  const showSnackbar = (
    message: string,
    severity: AlertColor = 'info',
    duration: number = 5000,
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarDuration(duration);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);

    const messageFromSearch = search.get('snackbar');

    if (messageFromSearch) {
      setSearch({}, { replace: true });
    }
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarDuration}
        onClose={handleClose}
        ContentProps={{
          sx: snackbarSeverity === 'error' ? { backgroundColor: 'error.main' } : {},
        }}
        message={snackbarMessage}
        action={
          <IconButton aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </SnackbarContext.Provider>
  );
};
