import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setSpace: ['space'],
  setEntity: ['entity'],

  resetEntities: [],
});

export const EntitiesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  entity: {},
});

/* ------------- Reducers ------------- */
//
// SET CURRENT SPACE
//
const setSpace = (state: any, { space }: any) => ({
  ...state,

  // TODO: rename this to space
  entity: space,
});

//
// RESET
//
const resetEntities = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SPACE]: setSpace,
  [Types.RESET_ENTITIES]: resetEntities,
});
