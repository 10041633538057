// NOTE: history is being pulled by react-router-dom
// if we install it on our own we might have mismatching vesrions
// import { createBrowserHistory } from '@remix-run/router';
import RouterActions from '../Redux/RouterRedux';
import { store } from '../Redux';

export default {
  push(...args: any[]) {
    store.dispatch(RouterActions.historyPush(args));
  },
};
