import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { currentLocale } from '../I18n';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  logoutRequest: null,
  logoutSuccess: null,
  logoutFailure: ['errors'],

  // Should be moved to ProfileRedux
  getProfileRequest: null,
  getProfileSuccess: ['currentUser'],
  getProfileFailure: ['errors'],

  // Should be moved to ProfileRedux
  updateProfileRequest: ['data', 'spaceId', 'callback'],
  updateProfileSuccess: ['newProfile'],
  updateProfileFailure: ['errors'],

  // Should be moved to ProfileRedux
  resetPasswordRequest: ['email', 'spaceId'],
  resetPasswordSuccess: ['message'],
  resetPasswordFailure: ['errors'],

  setStep: ['step'],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const initialState = {
  isLoggedIn: false,
  authToken: null,
  locale: currentLocale,
  currentUser: {
    included: {
      space: {
        name: '',
      },
    },
    tag_ids: [],
  },
  errors: null,
  isFetching: null,
  isFetchingProfile: null,
  message: null,
};

export const INITIAL_STATE = Immutable(initialState);

/* ------------- Reducers ------------- */

//
// PROFILE
// -
const getProfileRequest = (state: any) => ({
  ...state,
  isFetchingProfile: true,
  errors: null,
});
const getProfileSuccess = (state: any, { currentUser }: any) => ({
  ...state,
  isFetchingProfile: false,
  errors: null,
  isLoggedIn: true,
  currentUser,
  locale: currentUser.lang,
});
const getProfileFailure = (state: any, { errors }: any) => ({
  ...INITIAL_STATE,
  isFetchingProfile: false,
  errors,
});

const updateProfileRequest = (state: any) => ({
  ...state,
  isUpdatingProfile: true,
  errors: null,
});
const updateProfileSuccess = (state: any, { newProfile }: any) => ({
  ...state,
  isUpdatingProfile: false,
  currentUser: { ...state.currentUser, ...newProfile, password: null },
});
const updateProfileFailure = (state: any, { errors }: any) => ({
  ...state,
  isUpdatingProfile: false,
  errors,
});

// -
// LOGOUT
// -
const logoutRequest = (state: any) => ({
  ...state,
  isFetching: true,
  errors: null,
});
const logoutSuccess = () => ({
  ...INITIAL_STATE,
  isFetching: false,
});
const logoutFailure = (state: any, { errors }: any) => ({
  ...state,
  isFetching: false,
  errors,
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROFILE_REQUEST]: getProfileRequest,
  [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [Types.GET_PROFILE_FAILURE]: getProfileFailure,

  [Types.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [Types.UPDATE_PROFILE_FAILURE]: updateProfileFailure,

  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
});
