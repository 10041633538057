import { Locale } from '@formatjs/intl-utils/src/types';

const ETHICS_LINKS: Record<Locale, string> = {
  fr: 'https://fluicity-doc.notion.site/Charte-thique-84a2d55574304c9a869a662e1bd4b134',
  'ar-MA': 'https://fluicity-doc.notion.site/Code-of-Ethic-0b86292d338a4eb59a4539ae6d043cfb',
  en: 'https://fluicity-doc.notion.site/Code-of-Ethic-0b86292d338a4eb59a4539ae6d043cfb',
  es: 'https://fluicity-doc.notion.site/Code-of-Ethic-0b86292d338a4eb59a4539ae6d043cfb',
  nl: 'https://fluicity-doc.notion.site/Ethisch-charter-f515541a77f547a78af29f6bc81dcf78',
};

const PRIVACY_LINKS: Record<Locale, string> = {
  fr: 'https://fluicity-doc.notion.site/Politique-de-confidentialit-des-donn-es-3a8ae521faa44ab9b925155baf64e8ee',
  'ar-MA':
    'https://fluicity-doc.notion.site/Personal-Data-Privacy-Policy-08fa8fc0cb75428b964323a69de2b073',
  en: 'https://fluicity-doc.notion.site/Personal-Data-Privacy-Policy-08fa8fc0cb75428b964323a69de2b073',
  es: 'https://fluicity-doc.notion.site/Personal-Data-Privacy-Policy-08fa8fc0cb75428b964323a69de2b073',
  nl: 'https://fluicity-doc.notion.site/Privacybeleid-fc2e51eb142c4cc3845898ee47ca4b7c',
};

const TERMS_LINKS: Record<Locale, string> = {
  fr: 'https://fluicity-doc.notion.site/Conditions-g-n-rales-d-utilisation-8700d1a6a8164f2d86c5552f9bb3372f',
  'ar-MA':
    'https://fluicity-doc.notion.site/General-terms-and-condition-of-use-359a090fdec5455194337fbd70e4ee2a',
  en: 'https://fluicity-doc.notion.site/General-terms-and-condition-of-use-359a090fdec5455194337fbd70e4ee2a',
  es: 'https://fluicity-doc.notion.site/General-terms-and-condition-of-use-359a090fdec5455194337fbd70e4ee2a',
  nl: 'https://fluicity-doc.notion.site/Gebruiksvoorwaarden-291c18504a254b55b762ef656ee00892',
};

const FAQ_USER_LINKS: Record<Locale, string> = {
  fr: 'https://fluicity-doc.notion.site/Guide-utilisateur-46be8067f3f04a1583042455878a9453',
  'ar-MA': 'https://fluicity-doc.notion.site/User-Guide-af0edb3a6cc54636b6e256c460eb6a68',
  en: 'https://fluicity-doc.notion.site/User-Guide-af0edb3a6cc54636b6e256c460eb6a68',
  es: 'https://fluicity-doc.notion.site/User-Guide-af0edb3a6cc54636b6e256c460eb6a68',
  nl: 'https://fluicity-doc.notion.site/User-Guide-af0edb3a6cc54636b6e256c460eb6a68',
};

const FAQ_ADMIN_LINKS: Record<Locale, string> = {
  fr: 'https://fluicity-doc.notion.site/Guide-administrateur-c931f580c743417e906428d4c1234478',
  'ar-MA':
    'https://fluicity-doc.notion.site/Administrator-s-Guide-60d37a18338c4f71ae593157a032eff7',
  en: 'https://fluicity-doc.notion.site/Administrator-s-Guide-60d37a18338c4f71ae593157a032eff7',
  es: 'https://fluicity-doc.notion.site/Administrator-s-Guide-60d37a18338c4f71ae593157a032eff7',
  nl: 'https://fluicity-doc.notion.site/Administrator-s-Guide-60d37a18338c4f71ae593157a032eff7',
};

const ACCESSIBILITY_LINKS: Record<Locale, string> = {
  fr: 'https://fluicity-doc.notion.site/Accessibilit-4eaf99bc2d7d4d43adc52f1a9d796a4d',
  'ar-MA': 'https://fluicity-doc.notion.site/Accessibility-d1141b8d775b44a9b8e738d6525463f0',
  en: 'https://fluicity-doc.notion.site/Accessibility-d1141b8d775b44a9b8e738d6525463f0',
  es: 'https://fluicity-doc.notion.site/Accessibility-d1141b8d775b44a9b8e738d6525463f0',
  nl: 'https://fluicity-doc.notion.site/Accessibility-d1141b8d775b44a9b8e738d6525463f0',
};

export {
  ETHICS_LINKS,
  FAQ_USER_LINKS,
  FAQ_ADMIN_LINKS,
  PRIVACY_LINKS,
  TERMS_LINKS,
  ACCESSIBILITY_LINKS,
};
