import React from 'react';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { loginPath } from '../../Utils/routes';

function SignUpButton() {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate(loginPath());
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={handleRegisterClick}
      data-testid="sign-up-button"
      fullWidth
    >
      <FormattedMessage id="SIGN_IN" />
    </Button>
  );
}

export default SignUpButton;
