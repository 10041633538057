import googleCal from '../Assets/Icons/Calendar/google_cal.svg';
import icsCal from '../Assets/Icons/Calendar/ics_cal.svg';
import outlookCal from '../Assets/Icons/Calendar/outlook_cal.svg';
import verified from '../Assets/Icons/Entities/verified.svg';
import searchOff from '../Assets/Icons/Misc/search_off.svg';
import csamLoginIcon from '../Assets/Icons/Misc/csam.svg';
import csvFile from '../Assets/Icons/Misc/csv_file.svg';
import googleLoginIcon from '../Assets/Icons/Misc/google.svg';
import facebookLoginIcon from '../Assets/Icons/Misc/facebook.svg';
import cash from '../Assets/Icons/Misc/cash.svg';
import share from '../Assets/Icons/Misc/share.svg';
import bulb from '../Assets/Icons/Misc/bulb.svg';
import pin from '../Assets/Icons/Misc/pin.svg';
import unPin from '../Assets/Icons/Misc/unPin.svg';
import group from '../Assets/Icons/Misc/group.svg';
import chat from '../Assets/Icons/Misc/chat.svg';
import notFound from '../Assets/Icons/Misc/not_found.svg';
import offline from '../Assets/Icons/Misc/offline.svg';
import changeCircle from '../Assets/Icons/Misc/change_circle.svg';
import timeline from '../Assets/Icons/Misc/timeline.svg';
import checklist from '../Assets/Icons/Misc/checklist.svg';
import unpublish from '../Assets/Icons/Misc/unpublish.svg';
import accessPoint from '../Assets/Icons/Misc/access_point.svg';
import duplicate from '../Assets/Icons/Misc/duplicate.svg';
import coming from '../Assets/Icons/Posts/phases/coming.svg';
import change from '../Assets/Icons/Posts/status/change.svg';
import concretized from '../Assets/Icons/Posts/status/concretized.svg';
import inProgress from '../Assets/Icons/Posts/status/in_progress.svg';
import anomaly from '../Assets/Icons/Posts/Types/anomaly.svg';
import demand from '../Assets/Icons/Posts/Types/demand.svg';
import participatoryBudget from '../Assets/Icons/Posts/Types/participatory_budget.svg';

const icons = {
  calendar: {
    google_cal: googleCal,
    ics_cal: icsCal,
    outlook_cal: outlookCal,
  },
  entities: {
    verified,
  },
  misc: {
    searchOff,
    csamLoginIcon,
    googleLoginIcon,
    facebookLoginIcon,
    cash,
    csvFile,
    share,
    bulb,
    pin,
    unPin,
    group,
    chat,
    notFound,
    offline,
    changeCircle,
    timeline,
    checklist,
    unpublish,
    accessPoint,
    duplicate,
  },
  posts: {
    phases: {
      coming,
    },
    status: {
      change,
      concretized,
      in_progress: inProgress,
    },
    types: {
      anomaly,
      demand,
      idea: bulb,
      participatory_budget: participatoryBudget,
    },
  },
};

export default icons;
