import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { isAccessingViaCustomDomain } from '../Utils';
import { setSpaceSlug, getSpace, getSpaceByCustomDomain } from '../Services/Api';
import EntitiesActions from '../Redux/EntitiesRedux';
import ErrorPagesActions from '../Redux/ErrorPagesRedux';
import { SubscriptionStatuses } from '../Utils/Types';
import { Space } from '../Utils/Types/Space';
import { getHydrationDataAsResponse } from '../Utils/hydration';

const useSpace = ({ enabled = false } = {}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { spaceSlug = window.spaceSlug } = useParams();
  const accessFromCustomDomain = isAccessingViaCustomDomain();

  const {
    data = {},
    isLoading,
    error,
    refetch,
  } = useQuery(
    'currentSpace',
    async () => {
      let response;

      // If we have a hydrated space, we return it
      const hydratedSpaceResponse = getHydrationDataAsResponse('space');
      if (hydratedSpaceResponse) {
        return hydratedSpaceResponse;
      }

      if (accessFromCustomDomain) {
        response = await getSpaceByCustomDomain(window.location.host);

        // On a custom domain homemage, there's no slug to get from the URL so we get it from the response
        setSpaceSlug(response?.data?.data?.slug);
      } else {
        response = await getSpace(spaceSlug);
      }

      if (response?.status && [404, 500].includes(response.status)) {
        dispatch(ErrorPagesActions.showPage(response.status));
      }

      return response;
    },
    {
      select: (response: any) => response?.data?.data,
      refetchOnWindowFocus: false,
      placeholderData: {},
      enabled,
    },
  );

  const space = data as Space;

  useEffect(() => {
    if (spaceSlug) {
      setSpaceSlug(spaceSlug);
    }
  }, [spaceSlug]);

  useEffect(() => {
    // when the space is former_client we show a toast, and redirect to Engage home
    if (space.subscription_status === SubscriptionStatuses.FORMER_CLIENT) {
      window.location.href = `https://${
        window.runtimeEnv.REACT_APP_HOST_WWW
      }/home?snackbar=${intl.formatMessage({
        id: 'ERROR_PAGES.FORMER_CLIENT_TOAST',
      })}&severity=error`;
    } else {
      dispatch(EntitiesActions.setSpace(space));
    }
  }, [space?.id]);

  useEffect(() => {
    // This space variable is only for debugging
    // should not be read or used in the code
    window.space = space;
  }, [space]);

  return {
    space,
    error,
    isLoading,
    refetch,
  };
};

export default useSpace;
