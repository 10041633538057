export type LocaleType = 'fr' | 'en' | 'es' | 'nl' | 'ar';

interface LocaleDefinition {
  value: LocaleType;
  label: string;
}

export const LocalesDefinition: Array<LocaleDefinition> = [
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Español',
  },
  {
    value: 'nl',
    label: 'Nederlands',
  },
  {
    value: 'ar',
    label: 'العربية',
  },
];

export default LocalesDefinition;
