import { call, put } from 'redux-saga/effects';

import { extractErrors } from '../Services/ApiErrorHelpers';
import PostStatusActions from '../Redux/PostStatusRedux';

export default function* getPostStatus(api: any, { postId, postType }: any): any {
  const response = yield call(api.getPostStatus, postId);

  if (response.ok) {
    yield put(PostStatusActions.getPostStatusSuccess(postType, response.data.data));
  } else {
    yield put(PostStatusActions.getPostStatusFailure(postType, extractErrors(response)));
  }
}
