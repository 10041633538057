import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import FullScreenLoader from './FullScreenLoader';
import { loginPath } from '../Utils/routes';
import useSpace from '../Hooks/useSpace';

function SpaceRoute({
  component: Component,
  redirect = true,
  ...props
}: {
  component: React.FC<any & { space?: any }>;
  redirect?: boolean;
}) {
  const { space } = useSpace();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isFetchingProfile = useSelector((state: any) => state.auth.isFetchingProfile);

  if (isFetchingProfile) {
    return <FullScreenLoader />;
  }

  if (!space.public && !isLoggedIn && redirect) {
    return <Navigate replace to={loginPath()} />;
  }

  return <Component {...props} space={space} />;
}

export default SpaceRoute;
