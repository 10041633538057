import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { messages, currentLocale } from '../I18n';
import appsignal from '../Services/ErrorReportingService';

const onError = (error: any) => appsignal.sendError(error);

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps() {
  let locale = currentLocale;
  // If the locale is Arabic, we need to use the Moroccan variant to get the correct number formatting
  if (locale === 'ar') {
    locale = 'ar-MA';
  }

  return { locale, messages: messages[currentLocale], onError };
}

export default connect(mapStateToProps)(IntlProvider);
