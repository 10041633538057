import { call, put, select } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';

import AuthActions from '../Redux/AuthRedux';
import history from '../Services/history';
import Analytics from '../Services/AnalyticsService';
import { generateErrorsForWeb } from '../Services/ApiErrorHelpers';
import { loginPath, spacePath } from '../Utils/routes';
import { getHydrationDataAsResponse } from '../Utils/hydration';

export function* getProfile(api: any) {
  const hydratedProfileResponse = getHydrationDataAsResponse('profile');

  if (hydratedProfileResponse) {
    const currentUser = hydratedProfileResponse.data.data;

    yield put(AuthActions.getProfileSuccess(currentUser));

    return;
  }

  const response: ApiResponse<any> = yield call(api.getProfile);

  if (response.ok) {
    const currentUser = response.data.data;

    yield put(AuthActions.getProfileSuccess(currentUser));
  } else {
    yield put(AuthActions.getProfileFailure(response));
  }
}

export function* logout(api: any): any {
  const response: ApiResponse<any> = yield call(api.logout);
  const space = yield select((state: any) => state.entities.entity);

  if (response) {
    yield call(Analytics.track, 'Authentication - Logout');
    yield put(AuthActions.logoutSuccess());

    history.push(space.public ? spacePath() : loginPath());
  } else {
    const errors = generateErrorsForWeb(response.data);
    yield put(AuthActions.logoutFailure(errors));
  }
}
