// all the possible  transformations
// https://cloudinary.com/documentation/transformation_reference
const DEFAULT_TRANSFORMATION = 'dpr_2,f_auto';

// url config params we pass with cloudinary url
export const CloudinaryConfig = {
  AVATAR_48: 'q_auto:low,c_fill,g_auto,w_48,h_48',
  AVATAR_40: 'q_auto:low,c_fill,g_auto,w_40,h_40',
  AVATAR_30: 'q_auto:low,c_fill,g_auto,w_30,h_30',
};

/**
 * get cloudinary url through active storage key.
 * @param {string} publicId - active storage key .
 * @param {string} [transformation] - cloundinary transformation.
 * @param {string} [filename] - downloadable file name.
 * @return {string} url of the image from cloudinary
 *
 * @example
 *     cloudinaryImageUrl('fluicity-development/MK2ptbQ9m182FpxkXeF1YpnP', 'w_45,h_45,c_lpad', 'file.png')
 */
export const cloudinaryImageUrl = (publicId: any, transformation?: any, filename?: any): string => {
  if (publicId) {
    const transformationString = [DEFAULT_TRANSFORMATION, transformation].filter(Boolean).join(',');
    let downloadFilename = filename || publicId.split('/')[1];
    // remove file extension from name
    downloadFilename = downloadFilename.replace(/\.[A-Za-z]*$/, '');
    // remove special charachters from name
    downloadFilename = downloadFilename.replace(/[^a-zA-Z0-9_-]/g, '');

    return `https://res.cloudinary.com/fluicity/image/upload/${transformationString}/fl_attachment:${downloadFilename}/v1/${publicId}`;
  }

  return '';
};

export default {
  cloudinaryImageUrl,
  CloudinaryConfig,
};
