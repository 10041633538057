import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getPostStatusRequest: ['postId', 'postType'],
  getPostStatusSuccess: ['postType', 'statusesList'],
  getPostStatusFailure: ['postType', 'errors'],
});

export const PostStatusTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  byType: {},
});

const getPostStatusRequest = (state: any, { postType }: any) => ({
  ...state,

  byType: {
    ...state.byType,
    [postType]: {
      ...state.byType[postType],
      isLoading: true,
      errors: null,
    },
  },
});

const getPostStatusSuccess = (state: any, { postType, statusesList }: any) => ({
  ...state,

  byType: {
    ...state.byType,
    [postType]: {
      content: statusesList,
      isLoading: true,
      errors: null,
    },
  },
});

const getPostStatusFailure = (state: any, { postType, errors }: any) => ({
  ...state,

  byType: {
    ...state.byType,
    [postType]: {
      ...state.byType[postType],
      isLoading: true,
      errors,
    },
  },
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_POST_STATUS_REQUEST]: getPostStatusRequest,
  [Types.GET_POST_STATUS_SUCCESS]: getPostStatusSuccess,
  [Types.GET_POST_STATUS_FAILURE]: getPostStatusFailure,
});
