import { call, put } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { ApiResponse } from 'apisauce';

import ProfileActions from '../Redux/ProfileRedux';
import AuthActions from '../Redux/AuthRedux';
import { generateErrorsForWeb } from '../Services/ApiErrorHelpers';
import Analytics from '../Services/AnalyticsService';
import { setCurrentLocale } from '../I18n';

export function* getMyEntities(api: any) {
  const response: ApiResponse<any> = yield call(api.getMyEntities);

  if (response.ok) {
    yield put(ProfileActions.getMyEntitiesSuccess(response.data));
  } else {
    yield put(ProfileActions.getMyEntitiesFailure(generateErrorsForWeb(response.data)));
  }
}

export function* updateProfile(api: any, { data, spaceId, callback }: any): any {
  const cookies = new Cookies();
  const oldLang = cookies.get('lang');
  const newLang = data.lang;

  const response = yield call(api.updateProfile, data, spaceId);

  if (response.ok) {
    if (newLang && newLang !== oldLang) {
      setCurrentLocale(newLang);
    }
    yield put(AuthActions.updateProfileSuccess(response.data.data));

    // NOTE: this is used to refresh the space after updating the profile
    // TODO: separate entity subscription form to avoid reloading space to get the update
    if (callback) {
      callback();
    }
  } else {
    yield put(AuthActions.updateProfileFailure(generateErrorsForWeb(response.data)));
  }
}

export function* updatePassword(api: any, { currentPassword, newPassword, callback }: any): any {
  const response = yield call(api.updateMyPassword, currentPassword, newPassword);

  if (response.ok) {
    Analytics.track('Profile Settings', 'Update Password', 'Success');

    yield put(ProfileActions.updatePasswordSuccess());

    if (callback) {
      callback();
    }
  } else {
    const errors = generateErrorsForWeb(response.data);

    Analytics.track('Profile Settings', 'Update Password', 'Error');

    yield put(ProfileActions.updatePasswordFailure(errors));
  }
}

export function* createPassword(api: any, { password, callback }: any): any {
  const response = yield call(api.createPassword, password);

  if (response.ok) {
    yield put(ProfileActions.createPasswordSuccess());
    yield put(AuthActions.getProfileRequest());

    if (callback) {
      callback();
    }
  } else {
    const errors = generateErrorsForWeb(response.data);

    yield put(ProfileActions.createPasswordFailure(errors));
  }
}
