import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import FullScreenLoader from './FullScreenLoader';
import { loginPath } from '../Utils/routes';
import useSpace from '../Hooks/useSpace';

function AuthenticatedRoute({ component: Component, props }: any) {
  const { space } = useSpace();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isFetchingProfile = useSelector((state: any) => state.auth.isFetchingProfile);

  if (isFetchingProfile || !space?.slug) return <FullScreenLoader />;

  if (!isLoggedIn) {
    return <Navigate replace to={loginPath()} />;
  }

  return <Component space={space} {...props} />;
}

export default AuthenticatedRoute;
