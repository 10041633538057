import React from 'react';
import { uniqueId } from 'lodash';
import { Search as SearchIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { NavigationItem, NavigationItemGroup, NavigationItemKind } from '../Types/Navigation';
import { localizedAttributesFor } from '../Posts/Form/formBuilder';
import { WithNull } from '../Types/Utils';
import { Space } from '../Types/Space';
import { postPath } from '../routes';
import { LocaleType } from '../Types/Locales';

export const generateNewMenuItem = (
  kind: NavigationItemKind,
  intl: any,
  defaultLocale: LocaleType,
): NavigationItem => {
  const defaultData = {
    kind,
    id: uniqueId(`new-menu-item-${kind}-`),
    ...localizedAttributesFor('label'),
    ...localizedAttributesFor('url'),
    [`label_${defaultLocale}`]: intl.formatMessage({ id: 'MENU_ITEMS.NEW_ITEM' }),
    new_tab: false,
  };

  if (kind === 'group') {
    return defaultData;
  }

  if (kind === 'post') {
    return {
      ...defaultData,
      post_id: '',
    };
  }

  return defaultData;
};

export const generateEmptyMenuItem = (
  data: Partial<NavigationItem> = {},
): WithNull<NavigationItem> => {
  return {
    id: uniqueId('new-menu-item-'),
    kind: '',
    ...localizedAttributesFor('label'),
    ...localizedAttributesFor('url'),
    post_record_slug: '',
    parent_id: '',
    position: '',
    new_tab: false,
    ...data,
  };
};

export const isGroup = (item: NavigationItem | NavigationItem): item is NavigationItemGroup => {
  return item.kind === 'group';
};

export const hasMenuItemKind = (space: Space, kind: NavigationItemKind): boolean => {
  return space.included.menu_items.some((item) => item.kind === kind);
};

export interface MenuItemToMuiMenuItemProps {
  id: number | string;
  label?: string | null;
  menuItems?: MenuItemToMuiMenuItemProps[];
  component?: React.ElementType;
  href?: string | null;
  target?: string;
  hideLabel?: boolean;
  iconPosition?: 'start' | 'end';
  icon?: React.ReactNode;
  ariaLabel?: string;
}

const menuItemToMuiMenuItemProps = (
  item: NavigationItem,
  items: NavigationItem[],
  space: Space,
  intl: any,
): MenuItemToMuiMenuItemProps => {
  if (item.kind === 'group') {
    return {
      id: item.id,
      href: '#',
      label: item.label,
      icon: <ArrowDropDownIcon />,
      iconPosition: 'end',
      menuItems: items
        .filter((child) => child.parent_id === item.id)
        .sort((a, b) => a.position - b.position)
        .map((child) => menuItemToMuiMenuItemProps(child, items, space, intl)),
    };
  }

  if (item.kind === 'page_consultations') {
    return {
      id: item.id,
      href: `/${space.slug}/consultations`,
      label: item.label || intl.formatMessage({ id: 'MENU_ITEMS.PAGE_CONSULTATIONS' }),
    };
  }

  if (item.kind === 'page_news') {
    return {
      id: item.id,
      href: `/${space.slug}/news`,
      label: item.label || intl.formatMessage({ id: 'MENU_ITEMS.PAGE_NEWS' }),
    };
  }

  if (item.kind === 'page_about') {
    return {
      id: item.id,
      href: `/${space.slug}/about`,
      label: item.label || intl.formatMessage({ id: 'MENU_ITEMS.PAGE_ABOUT' }),
    };
  }

  if (item.kind === 'page_search') {
    return {
      id: item.id,
      href: `/${space.slug}/search`,
      icon: <SearchIcon />,
      hideLabel: true,
      label: item.label || intl.formatMessage({ id: 'MENU_ITEMS.PAGE_SEARCH' }),
      ariaLabel: 'search',
    };
  }

  if (item.kind === 'post') {
    return {
      id: item.id,
      href: postPath(space.slug, item.post_record_slug),
      label: item.label,
    };
  }

  return {
    id: item.id,
    href: item.kind === 'link' ? item.url : null,
    label: item.label,
    target: item.kind === 'link' ? '_blank' : undefined,
  };
};

export const generateMuiMenuItems = (space: Space, intl: any) => {
  const menuItems = space?.included?.menu_items || [];
  if (!space.slug || !menuItems.length) {
    return [];
  }

  const sortedRootMenuItems = space.included.menu_items
    .filter((item) => !item.parent_id)
    .sort((a, b) => a.position - b.position);
  return sortedRootMenuItems.map((item) =>
    menuItemToMuiMenuItemProps(item, menuItems, space, intl),
  );
};

const menuItemToApiPayload = (item: any, index: number) => {
  const formattedItem = {
    ...item,
    position: index,
  };

  // apply to menu_items
  if (formattedItem.menu_items_attributes) {
    formattedItem.menu_items_attributes = formattedItem.menu_items_attributes.map(
      (child: any, childIndex: number) => {
        return menuItemToApiPayload(child, childIndex);
      },
    );
  }

  return formattedItem;
};

const hasTemporaryId = (item: any) => {
  return typeof item.id === 'string' && item.id.includes('new-menu-item');
};

const removeTemporaryIds = (item: any) => {
  const updatedItem = { ...item };

  if (hasTemporaryId(updatedItem)) {
    delete updatedItem.id;
  }

  if (updatedItem.menu_items_attributes) {
    updatedItem.menu_items_attributes = updatedItem.menu_items_attributes.map((child: any) => {
      return removeTemporaryIds(child);
    });
  }

  return updatedItem;
};

export const generateFormData = (data: any, space: Space) => {
  const itemsToDelete = space.included?.menu_items
    .filter((item: any) => !data.menu_items.some((i: any) => i.id === item.id))
    .map((item: any) => {
      // we send the id and the _destroy flag
      return { id: item.id, _destroy: true };
    });

  const unflattenedItems = data.menu_items.reduce((acc: any, item: any, index: number) => {
    // *IF* the parent of the item is a "New Item" (not persisted)
    // we will pass the data of this item as nested attributes of the parent
    // because the parent doesn't have an id, so we can't reference it in the item.parent_id
    const parentItem = acc.find((i: any) => i.id === item.parent_id);
    if (parentItem && hasTemporaryId(parentItem)) {
      parentItem.menu_items_attributes = [
        ...(parentItem.menu_items_attributes || []),
        menuItemToApiPayload(item, index),
      ];

      // we also need to add the id of the item to the parent.menu_item_ids
      // but only if the item has a persisted id
      if (!hasTemporaryId(item)) {
        parentItem.menu_item_ids = [...(parentItem.menu_item_ids || []), item.id];
      }
    } else {
      // *ELSE* if the parent is already persisted (has an id)
      // we will just set the item.parent_id to the parent.id
      // and the item will move inside that parent automatically
      acc.push({
        ...menuItemToApiPayload(item, index),
      });
    }

    return acc;
  }, []);

  const formattedItems = unflattenedItems.map((item: any) => {
    return removeTemporaryIds(item);
  });

  return {
    menu_item_ids: data.menu_items
      .filter((item: any) => !hasTemporaryId(item))
      .map((i: any) => i.id),
    menu_items_attributes: [...itemsToDelete, ...formattedItems],
  };
};
