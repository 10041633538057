import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import AuthenticationMiddleware from './AuthenticationMiddleware';

// creates the store
export default (rootReducer: any, rootSaga: any) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];

  /* ------------- Authentication Modal Middleware ------------- */

  middleware.push(AuthenticationMiddleware);

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware({});
  middleware.push(sagaMiddleware);

  /* ------------- Assemble Middleware ------------- */
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
  );

  const store = createStore(rootReducer, enhancer);

  // kick off root saga
  sagaMiddleware.run(rootSaga);

  // configure persistStore
  const persistor = persistStore(store);

  return { persistor, store };
};
