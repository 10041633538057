import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  showPage: ['status'],
});

export const ErrorPagesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  status: null, // 404 or 500
});

/* ------------- Reducers ------------- */
const showPage = (state: any, { status = 5000 }) => ({
  ...state,
  status,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_PAGE]: showPage,
});
