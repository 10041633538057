import { createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

const REDUX_VERSION = 1;

const migrations = {
  [REDUX_VERSION]: (state: any) => state,
};

const REDUX_PERSIST = {
  storeConfig: {
    key: 'primary',
    version: REDUX_VERSION,
    storage,
    migrate: createMigrate(migrations),
    whitelist: ['modals'],
    stateReconciler: autoMergeLevel2,
  },
};

export default REDUX_PERSIST;
