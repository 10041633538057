const stripHtml = (html: string | null) => {
  if (!html) {
    return '';
  }

  // Create a new div element
  const temporalDivElement = document.createElement('div');

  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

export default stripHtml;
