import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

function useWidth(direction = 'up', mediaquery = 'sm') {
  const theme = useTheme();
  const check = useMediaQuery(theme.breakpoints[direction](mediaquery), { noSsr: true });

  return check;
}

export default useWidth;
