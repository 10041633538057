import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

function PrivacyCheckboxController({ name, control, error, helperText, label, space }: any) {
  const { formatMessage } = useIntl();

  if (!space || (!space.privacy_policy && !space.terms_of_use)) {
    return null;
  }

  let messageId: any;
  if (space.privacy_policy && space.terms_of_use) {
    messageId = 'AUTH.ACCEPT_SPACE_TERMS_AND_PRIVACY';
  } else if (space.privacy_policy) {
    messageId = 'AUTH.ACCEPT_SPACE_PRIVACY';
  } else {
    messageId = 'AUTH.ACCEPT_SPACE_TERMS';
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: formatMessage({ id: 'AUTH.ERRORS.REQUIRED_CHECKBOX' }),
      }}
      render={({ field: { value, onChange } }: any) => (
        <FormControl component="fieldset" error={error}>
          <FormControlLabel
            sx={{
              alignItems: 'flex-start',
              '& .MuiTypography-root': {
                marginTop: 1,
              },
            }}
            control={
              <Checkbox
                checked={value}
                onChange={(e: any) => onChange(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography color={error ? 'error' : 'textPrimary'}>
                {label || (
                  <FormattedMessage
                    id={messageId}
                    values={{
                      spaceName: space.name,
                      privacy: (chunk: any) => (
                        <MuiLink
                          component={Link}
                          color={error ? 'error' : 'primary'}
                          to={`/${space.slug}/pages/privacy-policy`}
                          target="_blank"
                          underline="hover"
                        >
                          {chunk}
                        </MuiLink>
                      ),
                      terms: (chunk: any) => (
                        <MuiLink
                          component={Link}
                          color={error ? 'error' : 'primary'}
                          to={`/${space.slug}/pages/terms`}
                          target="_blank"
                          underline="hover"
                        >
                          {chunk}
                        </MuiLink>
                      ),
                    }}
                  />
                )}
              </Typography>
            }
            // NOTE: adding the required html attribute makes it more accessible but prevents the error message from react-hook-form from showing. Omitting for now.
          />
          {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default PrivacyCheckboxController;
