import { some } from 'lodash';

export type Role = 'citizen' | 'contributor' | 'administrator' | 'fluimaster';
export type RoleUpperCase = Uppercase<Role>;

const Roles: Record<RoleUpperCase, Role> = {
  CITIZEN: 'citizen',
  CONTRIBUTOR: 'contributor',
  ADMINISTRATOR: 'administrator',
  FLUIMASTER: 'fluimaster',
};

// This function checks if the user has one of the roles passed in
// @param {string} currentRole - The current role of the user
// @param {string | string[]} roles - The roles to check against
// @returns {boolean} - Whether the user has the role
export const hasRole = (currentRole: Role, roles: Role | Role[]): boolean => {
  let list = roles;

  if (!currentRole) return false;
  if (!roles) return false;
  if (typeof roles === 'string') list = [roles];

  return some(list, (role) => role === currentRole);
};

export default Roles;
