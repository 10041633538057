import { PostType, Types } from './Posts';

export type StepType = 'submission' | 'analysis' | 'vote' | 'implementation';
export type StepTypeUppperCase = Uppercase<StepType>;

export type SteppablePostType = PostType &
  'ideas_request' &
  'participatory_budget' &
  'collaborative_decision' &
  'study';

type TypeSteps = {
  [key in StepTypeUppperCase]: StepType;
};

export const Steps: Record<SteppablePostType, TypeSteps> = {
  [Types.COLLABORATIVE_DECISION]: {
    SUBMISSION: 'submission',
    ANALYSIS: 'analysis',
    VOTE: 'vote',
    IMPLEMENTATION: 'implementation',
  },
  [Types.IDEAS_REQUEST]: {
    SUBMISSION: 'submission',
    IMPLEMENTATION: 'implementation',
  },
  [Types.PARTICIPATORY_BUDGET]: {
    SUBMISSION: 'submission',
    ANALYSIS: 'analysis',
    VOTE: 'vote',
    IMPLEMENTATION: 'implementation',
  },
  [Types.STUDY]: {
    SUBMISSION: 'submission',
    IMPLEMENTATION: 'implementation',
  },
};

export const AllSteps: Record<StepTypeUppperCase, StepType> = {
  SUBMISSION: 'submission',
  ANALYSIS: 'analysis',
  VOTE: 'vote',
  IMPLEMENTATION: 'implementation',
};

export const isSteppable = (type: PostType): type is SteppablePostType =>
  Object.keys(Steps).includes(type);

export const isSubmissionStep = (type: any) => AllSteps.SUBMISSION === type;

export default Steps;
