import '../Assets/Fonts/proximanova-styles.css';
import { ThemeOptions } from '@mui/material';

import { arSA, enUS, esES, frFR, nlNL, Localization } from '@mui/material/locale';
import { alpha, createTheme, darken, lighten } from '@mui/material/styles';

import { currentLocale } from '../I18n';

const colors = {
  primary: {
    main: '#5c5afa',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#38b9be',
    contrastText: '#ffffff',
  },
  success: {
    main: '#00d291',
    contrastText: '#ffffff',
  },
  info: {
    main: '#5c5afa',
    contrastText: '#ffffff',
  },
  neutral: {
    main: '#9D9D9D',
    contrastText: '#ffffff',
  },
};

const tonalOffset = {
  light: 0.93,
  dark: 0.3,
};

const disableAnimation = {
  // Name of the rule
  '@global': {
    '*, *::before, *::after': {
      transition: 'none !important',
      animation: 'none !important',
    },
  },
};

const defaultTheme: ThemeOptions = {
  themeName: 'Efalia Engage',
  direction: currentLocale === 'ar' ? 'rtl' : 'ltr',
  palette: {
    primary: {
      main: colors.primary.main,
      dark: darken(colors.primary.main, tonalOffset.dark),
      light: lighten(colors.primary.main, tonalOffset.light),
      contrastText: colors.primary.contrastText,
    },
    secondary: {
      main: colors.secondary.main,
      dark: darken(colors.secondary.main, tonalOffset.dark),
      light: lighten(colors.secondary.main, tonalOffset.light),
      contrastText: colors.secondary.contrastText,
    },
    success: {
      main: colors.success.main,
      dark: darken(colors.success.main, tonalOffset.dark),
      light: lighten(colors.success.main, 0.3),
      contrastText: colors.success.contrastText,
    },
    info: {
      main: colors.info.main,
      dark: darken(colors.info.main, tonalOffset.dark),
      light: lighten(colors.info.main, tonalOffset.light),
      contrastText: colors.info.contrastText,
    },
    neutral: {
      main: colors.neutral.main,
      dark: darken(colors.neutral.main, tonalOffset.dark),
      light: lighten(colors.neutral.main, tonalOffset.light),
      contrastText: colors.neutral.contrastText,
    },
    light: {
      main: '#fff',
      contrastText: colors.neutral.contrastText,
    },
    text: {
      // material ui uses rgba, which fails the contrast test
      // we translate the rgba values to rgb values with a background color of 'white'
      // calculated using: https://borderleft.com/toolbox/rgba/
      primary: 'rgb(33, 33, 33)',
      secondary: 'rgb(102, 102, 102)',
    },
    tonalOffset,
    contrastThreshold: 2.67,
  },
  typography: {
    fontFamily:
      currentLocale === 'ar' ? 'Noto Kufi Arabic, Proxima Nova' : 'Proxima Nova, Noto Kufi Arabic',
    fontDisplay: 'swap',
    fontWeightBold: 'bold',
    fontWeightMedium: 600,
    h1: {
      fontSize: '6rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '3.75rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '3rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '2.125rem',
      lineHeight: 1.18,
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1.5rem',
      lineHeight: 1.33,
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '1.25rem',
      lineHeight: 1.3,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.6,
      letterSpacing: '.00938em',
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
      letterSpacing: '.00938em',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
      letterSpacing: '.00938em',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
      letterSpacing: '.00938em',
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
      letterSpacing: '.035em',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.6,
      letterSpacing: '.00938em',
    },
    overline: {
      fontSize: '0.8125rem',
      lineHeight: 1.6,
      letterSpacing: '.035em',
      fontWeight: 600,
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }: any) => ({
          boxShadow: theme.shadows[1],
        }),
      },
    },
    // @ts-expect-error Object literal may only specify known properties, and 'MUIRichTextEditor' does not exist in type 'Components<Omit<Theme, "components">>'.
    MUIRichTextEditor: {
      styleOverrides: {
        editorContainer: {
          '& h2': {
            fontSize: '1.25rem !important', // Hack to avoid custom block in mui-rte
          },
          // paragraph blocks are being rendered as a div so we force some margin to make the div look like a p
          '.public-DraftEditor-content > div > div > .public-DraftStyleDefault-block': {
            margin: '1rem 0',
          },
        },
        placeHolder: {
          color: alpha('#000', 0.42),
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          color: '#424242',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      // Make it look like MD3 before MUI MD3 is released ;)
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        labelSmall: {
          paddingLeft: '10px',
          paddingRight: '10px',
        },
        labelMedium: {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#595959', // To have a contrast of 7 (WCAG aaa)
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: '60%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#c1c1c1', // For WCAG tools to read the contrast
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
        },
      },
    },
  },
};

if (process.env.NODE_ENV === 'test') {
  if (defaultTheme.components?.MuiCssBaseline?.styleOverrides) {
    defaultTheme.components.MuiCssBaseline.styleOverrides = {
      ...(defaultTheme.components.MuiCssBaseline.styleOverrides as Record<string, any>),
      ...disableAnimation,
    };
  }
}

const localeToVariant: Record<string, Localization> = {
  ar: arSA,
  en: enUS,
  es: esES,
  fr: frFR,
  nl: nlNL,
};

// The default values for the `defaultTheme` object can be found here:
// https://material-ui.com/customization/default-theme/
const engageTheme = createTheme(defaultTheme, localeToVariant[currentLocale]);

engageTheme.shadows[1] = '0px 1px 2px rgba(0, 0, 0, 0.18)';

export default engageTheme;
