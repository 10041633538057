import React from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import AuthActions from '../../../Redux/AuthRedux';
import Locales from '../../../Utils/Types/Locales';
import useSpace from '../../../Hooks/useSpace';
import { useSnackbar } from '../../../Utils/SnackbarContext';

function LanguageMenuItems({ onClick, nested = false }: any) {
  const intl = useIntl();
  const { space } = useSpace();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  if (!space) {
    return null;
  }

  const spaceLocales = Locales.filter((locale) => space.locales?.includes(locale.value));

  const handleOnClick = async (locale: string) => {
    if (isLoggedIn) {
      dispatch(
        AuthActions.updateProfileRequest({ lang: locale }, space.id, () => {
          queryClient.invalidateQueries();
          showSnackbar(intl.formatMessage({ id: 'PROFILE.PROFILE_UPDATED' }));
        }),
      );
    } else {
      navigate(`?lang=${locale}`, { replace: true });
      navigate(0); // Force refresh
    }

    onClick();
  };

  return spaceLocales.map((option: any) => (
    <ListItemButton
      key={option.value}
      onClick={() => handleOnClick(option.value)}
      role="list"
      sx={{ ...(nested && { pl: 4 }) }}
    >
      <ListItemText>{option.label}</ListItemText>
    </ListItemButton>
  ));
}

export default LanguageMenuItems;
