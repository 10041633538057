import appsignal from '../Services/ErrorReportingService';

export type HydrationDataType = 'space' | 'profile';

const parseHydrationData = (data: string) => {
  try {
    // A hack to prevent the malcoded data from breaking the JSON.prase
    const encodeddata = encodeURIComponent(data);
    const decodeddata = decodeURIComponent(encodeddata);

    return JSON.parse(decodeddata);
  } catch (e) {
    appsignal.sendError(e);
    return null;
  }
};

export function getHydrationDataAsResponse(dataType: HydrationDataType) {
  const hydratedData = window.hydration[dataType];

  if (!hydratedData) {
    return null;
  }

  // we parse the hydration data
  const data = parseHydrationData(hydratedData);

  // we clear the hydration data after consuming it
  // this is to prevent the data from being reused
  window.hydration[dataType] = undefined;

  if (data) {
    return { data };
  }

  return null;
}
