import { createInstance } from '@datapunt/matomo-tracker-react';
import ahoy from 'ahoy.js';

export const SESSION_STORE_SPACE_SLUG_KEY = 'spaceSlug';

const updateSpaceSlug = (spaceSlug: any) => {
  sessionStorage.setItem(SESSION_STORE_SPACE_SLUG_KEY, spaceSlug);
};

// return an Object of the form { 'Current space': [1], 'Post ID': [3, 7], ... }
const customDimensions = (window.runtimeEnv?.REACT_APP_MATOMO_CUSTOM_DIMENSIONS || '')
  .split(',')
  .reduce((acc: any, curr: any, index: any) => {
    if (acc[curr]) {
      acc[curr] = [...acc[curr], index + 1];
    } else {
      acc[curr] = [index + 1];
    }
    return acc;
  }, {});

const track = (category: any, action: any, name: any = null, properties: any = {}) => {
  // disable tracking for prerender service
  if (navigator.userAgent.includes('prerender')) return;

  const keys = Object.keys(properties || {});
  (keys || []).forEach((customDimensionName) => {
    const ids = customDimensions[customDimensionName] || [0];

    // we have Post ID dimension as a Visits dimension and an action dimension, so we set it 2 times
    ids.forEach((id: any) => {
      if (id !== 0) {
        window._paq.push(['setCustomDimension', id, properties[customDimensionName]]);

        if (window.runtimeEnv?.REACT_APP_ANALYTICS_LOGS_IS_ENABLED === 'true') {
          console.info(
            'Matomo - set custom dimension',
            id,
            `${customDimensionName}: ${properties[customDimensionName]}`,
          );
        }
      }
    });
  });

  window._paq.push(['trackEvent', category, action, name]);

  if (window.runtimeEnv?.REACT_APP_ANALYTICS_LOGS_IS_ENABLED === 'true') {
    console.info(category, action, name, properties);
  }
};

const matomoInstance = createInstance({
  urlBase: 'https://fluicity.matomo.cloud',
  disabled: window.runtimeEnv?.REACT_APP_ANALYTICS_IS_ENABLED !== 'true',
  linkTracking: false, // !important
  siteId: process.env.NODE_ENV !== 'production' ? 2 : 1,
  configurations: {
    disableCookies: true,
  },
});

ahoy.configure({
  cookies: false,
  trackVisits: false,
});

export default {
  updateSpaceSlug,
  track,
  matomoInstance,
  client: ahoy,
};
