import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react';

import AnalyticsService from '../Services/AnalyticsService';
import { store } from '../Redux';
import StartupActions from '../Redux/StartupRedux';
import ConnectedIntlProvider from './ConnectedIntlProvider';
import appsignal from '../Services/ErrorReportingService';

function RootContainer({ children }: any) {
  React.useEffect(() => {
    store.dispatch(StartupActions.startup(store));
  }, []);

  return (
    <AppSignalErrorBoundary instance={appsignal}>
      <MatomoProvider value={AnalyticsService.matomoInstance}>
        <Router>
          <Provider store={store}>
            <ConnectedIntlProvider>{children}</ConnectedIntlProvider>
          </Provider>
        </Router>
      </MatomoProvider>
    </AppSignalErrorBoundary>
  );
}

export default RootContainer;
