import qs from 'qs';

import { isAccessingViaCustomDomain } from './customDomain';
import { postTypePlural, getCategoryForPostType, PostType } from './Types/Posts';
import { dashboardPaths } from './Dashboard';
import { isAdminGeneratedContent } from './Post/Form';
import { Tab } from './Post/tabs';

const getSpaceHost = (entityCustomDomain: any) => {
  let host = window.runtimeEnv?.REACT_APP_HOST_WWW;

  if (entityCustomDomain) {
    host = entityCustomDomain;
  }

  return host;
};

const getSpaceUrl = (spaceSlug: string, entityCustomDomain: string | null): string => {
  const entityHost = getSpaceHost(entityCustomDomain);
  const slug = entityCustomDomain ? '' : `/${spaceSlug}`;

  return `//${entityHost}${slug}`;
};

export const newPostPath = (
  spaceSlug: string,
  postType: PostType,
  queryParams: any = {},
): string => {
  const newPath = `/${spaceSlug}/${postTypePlural(postType)}/new`;
  const params = qs.stringify(queryParams);

  return queryParams ? newPath.concat(`?${params}`) : newPath;
};

export const postPath = (spaceSlug: string, postSlug: string | number, query = {}) => {
  const basePath = `/${spaceSlug || window.spaceSlug || ''}/posts/${postSlug}`;
  if (query && Object.keys(query).length) {
    return `${basePath}?${qs.stringify(query, {
      arrayFormat: 'brackets',
    })}`;
  }
  return basePath;
};

export const editPostPath = (
  spaceSlug: string,
  postSlug: string,
  postType: PostType,
  tab?: Tab,
) => {
  if (isAdminGeneratedContent(postType)) {
    return `/${spaceSlug}/dashboard/posts/${postSlug}/edit${tab ? `/${tab}` : ''}`;
  }

  return `/${spaceSlug}/posts/${postSlug}/edit`;
};

export const dashboardPostPath = (spaceSlug: string, postSlug: string, postType: PostType) => {
  const postCategory = getCategoryForPostType(postType);
  const path = dashboardPaths[postCategory];

  return `/${spaceSlug}/dashboard/${path}/${postSlug}`;
};

export const dashboardEditPostPath = (spaceSlug: any, postSlug: any) => {
  return `/${spaceSlug}/dashboard/posts/${postSlug}/edit`;
};

const isPageSpecificToASpace = () => {
  const currentPath = window.location.pathname;
  const isHomepage = currentPath === '/';

  if (isHomepage && !isAccessingViaCustomDomain()) {
    return false;
  }

  return currentPath !== '/home';
};

/**
 * @param {string} [path]
 * @param {Object} [query] - a query object
 * @returns {string} new path scoped to the space
 */
const spacePath = (path: string = '', query: any = {}): string => {
  if (query && Object.keys(query).length) {
    return `/${window.spaceSlug}/${path}?${qs.stringify(query, {
      arrayFormat: 'brackets',
    })}`;
  }

  return `/${window.spaceSlug}/${path}`;
};

const loginPath = (redirectTo: string | null = null): string => {
  const currentPath = window.location.pathname + window.location.search;

  return spacePath('login', { redirect_to: redirectTo || currentPath });
};

const signupPath = (redirectTo: any, queryParams = {}): string => {
  const currentPath = window.location.pathname + window.location.search;

  return spacePath('signup', { ...queryParams, redirect_to: redirectTo || currentPath });
};

export { getSpaceHost, getSpaceUrl, isPageSpecificToASpace, loginPath, spacePath, signupPath };
