import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  historyPush: ['args'],
  historyClear: null,
});

export const ModalsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  args: null,
});

/* ------------- Reducers ------------- */
const historyPush = (state: any, { args }: any) => ({
  ...state,
  args,
});

const historyClear = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.HISTORY_PUSH]: historyPush,
  [Types.HISTORY_CLEAR]: historyClear,
});
