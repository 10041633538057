import React from 'react';
import { Box, Link as MuiLink, useMediaQuery, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { isAccessingViaCustomDomain } from '../../Utils/customDomain';
import CloudinaryImage from '../DesignSystem/CloudinaryImage';

const getHref = (currentEntity: any) => {
  if (isAccessingViaCustomDomain()) {
    return '/';
  }

  if (currentEntity.slug) {
    return `/${currentEntity.slug}`;
  }

  return '/';
};

function EngageLogo({ currentEntity, isLoading }: any) {
  const theme = useTheme();
  let [logoWidth, logoHeight] = [105, 42];
  const spaceHasLogo = !!currentEntity.brand_logo?.s3_key;

  if (useMediaQuery(`${theme.breakpoints.up('xs')} and (orientation: landscape)`)) {
    [logoWidth, logoHeight] = [95, 38];
  }

  if (useMediaQuery(theme.breakpoints.up('sm'))) {
    [logoWidth, logoHeight] = [115, 46];
  }

  if (isLoading && !currentEntity.id) {
    return <Skeleton width={logoWidth} height={logoHeight} />;
  }

  return (
    <MuiLink to={getHref(currentEntity)} component={Link} underline="hover">
      <Box display="flex">
        <CloudinaryImage
          publicId={currentEntity.brand_logo?.s3_key}
          height={logoHeight}
          width={spaceHasLogo ? 'auto' : logoWidth}
          alt={`Logo ${currentEntity.name}`}
          transformation={`h_${logoHeight},q_auto:low`}
        />
      </Box>
    </MuiLink>
  );
}

export default EngageLogo;
