import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle as MUIDialogTitle, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DialogTitle({ id, content, onClose, dismissible, dir = 'ltr' }: any) {
  return (
    <MUIDialogTitle id={id}>
      <Typography
        variant="h5"
        component="span"
        sx={{ [dir === 'rtl' ? 'marginLeft' : 'marginRight']: 3, display: 'block' }}
      >
        {content}
      </Typography>

      {dismissible && (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            [dir === 'rtl' ? 'left' : 'right']: 8,
            top: 8,
            color: (theme: any) => theme.palette.grey[500],
          }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MUIDialogTitle>
  );
}

DialogTitle.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  dir: PropTypes.string,
  dismissible: PropTypes.bool,
};

export default DialogTitle;
