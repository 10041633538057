import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import ErrorPage from '../../Pages/ErrorPage';
import RootContainer from '../RootContainer';
import { createCustomMuiTheme, engageMuiTheme } from '../../Themes';

const notFoundContainer = document.getElementById('not-found-page');
const serverErrorContainer = document.getElementById('server-error-page');
const offlineContainer = document.getElementById('offline-page');

let status = 500;
if (notFoundContainer) {
  status = 404;
} else if (offlineContainer) {
  status = 0; // not sure if we can use any other meaninful value
} else {
  status = 500;
}

const container = notFoundContainer || serverErrorContainer || offlineContainer;

if (container) {
  const name = container.getAttribute('data-space-name');
  const slug = container.getAttribute('data-space-slug');
  const primaryColor = container.getAttribute('data-primary-color');
  const secondaryColor = container.getAttribute('data-secondary-color');
  const root = createRoot(container);

  root.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createCustomMuiTheme(engageMuiTheme, primaryColor, secondaryColor)}>
        <CssBaseline />

        <RootContainer>
          <ErrorPage status={status} name={name} slug={slug} />
        </RootContainer>
      </ThemeProvider>
    </StyledEngineProvider>,
  );
}
